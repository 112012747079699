import { Box, Typography, Button, ThemeProvider } from '@mui/material'
import styled from '@emotion/styled'
import { ChevronLeft } from '@mui/icons-material'
import { defaultTheme } from '../../theme'
import { theme } from '../../avenue-8-platform-style'

export type InternalNavbarType = {
  onBackButtonClicked?: () => void
  showBackButton?: boolean
  title: string
  rightButtonComponent?: JSX.Element
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export default function InternalNavbar({
  showBackButton,
  title,
  rightButtonComponent = null,
  onBackButtonClicked = null,
}: InternalNavbarType): JSX.Element {
  return (
    <Container>
      {showBackButton && (
        <Box
          sx={{
            marginBottom: 2,
            width: '100%',
            backgroundColor: {
              xs: defaultTheme.background,
            },
          }}
        >
          <ThemeProvider theme={theme}>
            <Button
              startIcon={<ChevronLeft />}
              variant="text"
              onClick={() => onBackButtonClicked?.()}
              sx={{
                padding: '12px 0',
                color: defaultTheme.primaryColor,
                fontWeight: 400,
                letterSpacing: 2,
                fontSize: '12px',
              }}
            >
              Back
            </Button>
          </ThemeProvider>
        </Box>
      )}

      <Wrapper>
        <Typography
          fontFamily="Canela"
          fontWeight={400}
          sx={{ fontSize: { xs: 20, md: 24 } }}
        >
          {title}
        </Typography>
        <div>{rightButtonComponent}</div>
      </Wrapper>
    </Container>
  )
}
