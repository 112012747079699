import React, { useState, useEffect } from 'react'
import {
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
  Typography,
  Grid,
} from '@mui/material'
import styled from '@emotion/styled'
import { RadioIcon } from '../Icons'

export type CreditCardInfo = {
  lastFourDigits: string
  brandName: string
  paymentProviderId: string
}

export type CreditCardSelectorProps = {
  cards: CreditCardInfo[]
  creditCardComponent: JSX.Element
  onCreditCardChange: (data: any) => void
}

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`

const CustomRadio = styled(Radio)`
  &.Mui-checked svg {
    color: #73738c;
  }
`

export default function CreditCardSelector({
  cards,
  creditCardComponent,
  onCreditCardChange,
}: CreditCardSelectorProps): JSX.Element {
  const [showCreditCardForm, setShowCreditCardForm] = useState(false)
  const [value, setValue] = useState<string>('new-credit-card')

  useEffect(() => {
    if (cards.length > 0) {
      setValue(cards[0].paymentProviderId)
    }
  }, [cards])

  const renderCreditCardOption = () => {
    const options = cards.map((card, index) => {
      return (
        <FormControlLabel
          key={index + card.lastFourDigits}
          value={card.paymentProviderId}
          control={
            <Radio
              checkedIcon={<RadioIcon />}
              icon={<RadioIcon color="transparent" />}
            />
          }
          label={
            <Typography variant="body2">{`${card.brandName} ending in ${card.lastFourDigits}`}</Typography>
          }
        />
      )
    })

    return [
      ...options,
      <FormControlLabel
        key={cards.length + 'new-card'}
        control={
          <CustomRadio
            checkedIcon={<RadioIcon />}
            icon={<RadioIcon color="transparent" />}
          />
        }
        value="new-credit-card"
        label={
          <Typography variant="body2">{'Use a different card'}</Typography>
        }
      />,
    ]
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    setShowCreditCardForm(event.target.value === 'new-credit-card')
    onCreditCardChange(event.target.value)
  }

  return (
    <Container>
      <RadioGroup
        aria-label="credit-card"
        onChange={handleChange}
        value={value}
      >
        {renderCreditCardOption()}
      </RadioGroup>
      {showCreditCardForm && (
        <Grid
          container
          flexDirection="column"
          sx={{ marginTop: '20px', marginLeft: { md: '30px' } }}
        >
          {creditCardComponent}
        </Grid>
      )}
    </Container>
  )
}
