import * as React from 'react'
import styled from '@emotion/styled'
import { ChevronIcon } from '../Icons/ChevronIcon'
import { CheckIcon } from '../Icons/CheckIcon'

const StepperItemContainer = styled.button<{ $state?: string }>`
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  letter-spacing: 2px;
  color: ${(p) =>
    p.$state === 'active'
      ? p.theme.platformStyle.typography.primaryColor
      : p.theme.platformStyle.typography.secondaryColor};
  font-weight: ${(p) => (p.$state === 'active' ? '500' : '400')};
  &:first-of-type {
    padding-left: 0;
  }
  cursor: ${(p) => (p.onClick ? 'pointer' : 'initial')};
`

const TextContainer = styled.span`
  width: 100%;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  font-family: ${(p) => p.theme.platformStyle.typography.fontFamily};
  text-align: left;
`

const IconContainer = styled.i`
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
  min-width: 24px;
`

const StepperItem = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & {
    currentStep: number
    order: number
  },
): JSX.Element => {
  const { children, currentStep, order, ...rest } = props
  const state =
    currentStep === order
      ? 'active'
      : currentStep > order
      ? 'completed'
      : 'idle'
  const getStateIcon = () => {
    switch (state) {
      case 'active':
        return <ChevronIcon />
      case 'completed':
        return <CheckIcon />
      default:
        return null
    }
  }

  return (
    <StepperItemContainer {...rest} $state={state}>
      <IconContainer>{getStateIcon()}</IconContainer>
      <TextContainer>{children}</TextContainer>
    </StepperItemContainer>
  )
}

export default StepperItem
