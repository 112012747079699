import { createIcon, IconOptions } from './createIcon'

function IconContent({ color, backgroundColor }: IconOptions) {
  return (
    <>
      <rect
        x="5.5"
        y="3.5"
        width="15"
        height="15"
        rx="1.5"
        fill={backgroundColor}
        stroke={color}
      />
      <path d="M2 7V20C2 21.1046 2.89543 22 4 22H17" stroke={color} />
      <path d="M6 18L9.5 14.5L11 16L16 11L20.5 15.5" stroke={color} />
      <circle cx="11" cy="9" r="1.5" stroke={color} />
    </>
  )
}

export const ImageIcon = createIcon(IconContent)
