import styled from '@emotion/styled'
import {
  Typography,
  TypographyVariant,
  Switch as MuiSwitch,
  FormControlLabel,
} from '@mui/material'

const SwitchWrapper = styled.div`
  display: flex;
`

const CustomFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
  }
`

const CustomSwitch = styled(MuiSwitch)<{
  $withLeftMargin: boolean
  $withRightMargin: boolean
}>`
  &.MuiSwitch-root {
    width: 40px;
    height: 21px;
    padding: 0;
    display: flex;
    margin-right: ${(p) => (p.$withRightMargin ? '10px' : '0')};
    margin-left: ${(p) => (p.$withLeftMargin ? '10px' : '0')};
  }
  & > .MuiSwitch-switchBase {
    padding: 3px;
  }
  & .MuiSwitch-thumb {
    width: 15px;
    height: 15px;
    box-shadow: none;
    background-color: #fff;
  }
  & .MuiSwitch-track {
    border-radius: 30px;
    background-color: #73738c !important;
  }
  & > .Mui-checked {
    transform: translateX(19px) !important;
    & + .MuiSwitch-track {
      opacity: 1 !important;
    }
  }
  & > :not(.Mui-checked) {
    & + .MuiSwitch-track {
      opacity: 0.5 !important;
    }
  }
`

export interface SwitchProps {
  label?: string
  labelPosition?: 'end' | 'start' | 'top' | 'bottom'
  labelVariant?: TypographyVariant | 'inherit'
  secondLabel?: string
  name?: string
  onChange?: (toggleValue: boolean) => void
  state?: boolean
}

export default function Switch({
  label,
  labelPosition = 'end',
  labelVariant = 'body1',
  secondLabel,
  name,
  onChange,
  state = false,
}: SwitchProps): JSX.Element {
  return (
    <SwitchWrapper>
      {secondLabel && labelPosition === 'end'
        ? <Typography variant={labelVariant}>{secondLabel}</Typography>
        : null}
      <CustomFormControlLabel
        control={
          <CustomSwitch
            $withLeftMargin={!!secondLabel || labelPosition === 'start'}
            $withRightMargin={!!secondLabel || labelPosition === 'end'}
            checked={state}
            onChange={() => onChange?.(!state)}
            name={name ?? ''}
          />
        }
        label={
          label ? <Typography variant={labelVariant}>{label}</Typography> : ''
        }
        labelPlacement={labelPosition}
      />
      {secondLabel && labelPosition === 'start'
        ? <Typography variant={labelVariant} style={{ marginLeft: 10 }}>
            {secondLabel}
          </Typography>
        : null}
    </SwitchWrapper>
  )
}
