import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0944 4.33161L14.662 5.76396L19.8065 10.9084L2.55299 10.8514L2.6439 12.9619L19.8974 13.0189L14.5333 18.3837L16.0946 19.945L23.9008 12.1381L16.0944 4.33161Z"
        fill={color}
      />
    </>
  )
}

export const ArrowIcon = createIcon(IconContent)
