import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <>
      <path d="M18 9.00002L10.9289 16.0711L7.3934 12.5355" stroke={color} />
    </>
  )
}

export const CheckIcon = createIcon(IconContent)
