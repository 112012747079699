import { createIcon, IconOptions } from './createIcon'

function IconContent({
  color,
  backgroundColor,
  borderColor = '#D5D6EA',
}: IconOptions) {
  return (
    <>
      <path
        d="M0.900391 4.32562C0.900391 2.39263 2.46739 0.825623 4.40039 0.825623H20.4004C22.3334 0.825623 23.9004 2.39263 23.9004 4.32562V20.3256C23.9004 22.2586 22.3334 23.8256 20.4004 23.8256H4.40039C2.46739 23.8256 0.900391 22.2586 0.900391 20.3256V4.32562Z"
        fill={backgroundColor}
        stroke={borderColor}
      />
      <path
        d="M18.4004 8.32562L11.3293 15.3967L7.79379 11.8612"
        stroke={color}
        strokeWidth="1.5"
      />
    </>
  )
}

export const CheckboxAltIcon = createIcon(IconContent)
