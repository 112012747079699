/* eslint-disable multiline-ternary */

import { SpreadsheetIcon } from '../Icons/SpreadsheetIcon'
import { muiDefaultTheme as theme } from '../../theme/material-themes'
import styled from '@emotion/styled'
import { Button, Typography, Box } from '@mui/material'
import { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import UploadingFile from './UploadProgressBar'
import FileUploaderProps from './FileUploaderProps'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 12rem;
`

const FileUploaderBox = styled(Box)<{ disabled?: boolean }>`
  ${(p) => (p.disabled === true ? 'pointer-events: none;' : 'cursor: pointer;')}
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: ${(p) => (p.disabled === true ? 'rgba(0,0,0,.2)' : '#D5D6EA')};
  border: 1px ${(p) => (p.disabled === true ? 'rgba(0,0,0,.2)' : '#D5D6EA')}
    dashed;
  box-sizing: border-box;
  padding: 2rem 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  overflow: hidden;
  width: 100%;
  outline: none !important;
  p {
    margin: 0.25rem 0;
  }
  input {
    border-radius: 1rem;
    outline: none !important;
  }
  gap: 1rem;
`

const DragAndDropText = styled(Typography)`
  font-size: 0.875rem;
  padding: 0 10rem;
`

const BrowseFileButton = styled(Button)`
  border-color: #d5d6ea;
  padding-left: 1.2rem;
  &:hover {
    border-color: #d5d6ea;
  }
`

const DownloadButton = styled(Button)`
  color: ${(p) => theme.palette.secondary.main};
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.05rem;
  text-decoration: underline;
  padding-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
`

const ErrorText = styled(Typography)`
  color: ${(p) => theme.palette.error.main};
  font-size: 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  text-align: center;
  width: 100%;
  left: 0;
  margin-top: 1rem !important;
`

const MaxSizeText = styled(Typography)`
  font-size: 0.75rem;
  letter-spacing: 2px;
`

const UploadingFilesContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ErrorMessage = ({ message }: { message?: string }) => {
  return <ErrorText variant="body2">{message ?? ''}</ErrorText>
}

const ONE_MB = 1000000 // 1000000 bytes = 1Mb

const validateRejectedFiles = (
  files: FileRejection[],
  maxSize: number,
  maxFiles: number,
): string => {
  let errorMessage = ''

  // display the first error by default
  const { errors, file } = files.find((file) => file.errors?.length)

  switch (errors?.[0].code) {
    case 'file-too-large':
      {
        const fileSizeInMb = (file.size / ONE_MB).toFixed(1)
        const maxSizeInMb = (maxSize / ONE_MB).toFixed(1)
        // prettier-ignore
        errorMessage = `"${file.name}" size  (${fileSizeInMb} MB) exceeds the maximum limit (${maxSizeInMb} MB)`
      }
      break
    case 'file-invalid-type':
      errorMessage = `Type of "${file.name}" is not accepted`
      break
    case 'too-many-files':
      errorMessage = `Maximum number of accepted files is: ${maxFiles}, received ${files.length}`
      break
    default:
      // prettier-ignore
      errorMessage = 'Unable to upload the files, please check file types, sizes and try again'
      break
  }

  return errorMessage
}

export default function FileUploader({
  onFileUploaded,
  onFileDeleted,
  Icon = <SpreadsheetIcon color="#73738C" />,
  buttonText = 'Browse Files',
  allowedMimeTypes,
  helperText,
  template,
  maxSize,
  maxFiles = 1,
  showMaxSizeLabel = false,
  defaultValue = [],
  showUploadingFiles = true,
  fileProps = {},
}: FileUploaderProps): JSX.Element {
  const [uploadingFiles, setUploadingFiles] = useState<File[]>(defaultValue)
  const [errorMessage, setErrorMessage] = useState(null)
  const { id = 'file' } = fileProps
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    maxFiles,
    maxSize,
    accept: allowedMimeTypes.join(','),
    onDropAccepted: async (acceptedFiles: File[]) => {
      setErrorMessage(null)
      const fileNames = uploadingFiles.map((file) => file.name)

      const uniqueFiles = [
        ...uploadingFiles,
        ...acceptedFiles.filter((file) => !fileNames.includes(file.name)),
      ]

      setUploadingFiles([...uniqueFiles])
      onFileUploaded({
        receivedFiles: acceptedFiles,
        uploadingFiles: uniqueFiles,
      })
    },
    onDropRejected: (rejectedFiles: FileRejection[]) => {
      const errorMessage = validateRejectedFiles(
        rejectedFiles,
        maxSize,
        maxFiles,
      )
      setErrorMessage(errorMessage)
    },
  })

  const deleteUploadedFile = (file: File) => {
    const filteredUploadingFiles = uploadingFiles.filter(
      (uploadingFile) =>
        uploadingFile.name !== file.name &&
        uploadingFile.lastModified !== file.lastModified,
    )

    setUploadingFiles([...filteredUploadingFiles])
    onFileDeleted?.({
      deletedFile: file,
      uploadingFiles: filteredUploadingFiles,
    })
  }

  return (
    <Wrapper color="grey">
      <FileUploaderBox
        {...getRootProps({
          className: 'dropzone',
          id: `${id}-dropzone`,
        })}
      >
        <input {...getInputProps()} {...fileProps} type="file" />
        {Icon}
        <DragAndDropText color="secondary">{helperText}</DragAndDropText>
        <BrowseFileButton disableRipple size="small" onClick={open}>
          {buttonText}
        </BrowseFileButton>
        {!!errorMessage && <ErrorMessage message={errorMessage} />}
        {template ? (
          <DownloadButton variant="text" disableRipple href={template.link}>
            {template.text}
          </DownloadButton>
        ) : null}

        {showMaxSizeLabel && maxSize > 0 ? (
          <MaxSizeText variant="body2" color="secondary">
            MAX SIZE {(maxSize / ONE_MB).toFixed(1)} MB
          </MaxSizeText>
        ) : null}
      </FileUploaderBox>

      {showUploadingFiles && uploadingFiles.length > 0 && (
        <UploadingFilesContainer id={`${id}-uploading-files-list`}>
          {uploadingFiles.map((file) => (
            <UploadingFile
              icon={Icon}
              key={`${file.name}-${file.lastModified}`}
              name={file.name}
              onDelete={() => deleteUploadedFile(file)}
            />
          ))}
        </UploadingFilesContainer>
      )}
    </Wrapper>
  )
}
