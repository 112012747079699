import styled from '@emotion/styled'
import * as React from 'react'
import { theme } from '../../avenue-8-platform-style'

const MIN_RESOURCES_SIZE = 24 //this is the frame size of our SVG icons (see Figma)

export interface IconOptions {
  title?: string
  className?: string
  color?: string
  backgroundColor?: string
  borderColor?: string
  density?: 'small' | 'medium' | 'large'
  size?: number
  rotate?: number
}

const StyledSvg = styled.svg`
  ${(p) => p.rotate && 'transition: transform 0.3s;'}
  ${(p) => p.rotate && `transform: rotate(${p.rotate}deg);`}
`

export function createIcon(
  SvgContentComponent: (props: IconOptions) => any,
  { resourceSize }: { resourceSize?: number } = {},
): any {
  return React.memo(function (iconOptions: IconOptions) {
    let size = MIN_RESOURCES_SIZE

    resourceSize = resourceSize || MIN_RESOURCES_SIZE
    if (iconOptions.density === 'medium') {
      size = 36
    } else if (iconOptions.density === 'large') {
      size = 48
    }
    const min = (resourceSize - size) / 2
    const viewBox = `${min} ${min} ${size} ${size}`
    const color = iconOptions.color || 'currentColor'
    const backgroundColor =
      iconOptions.backgroundColor || theme.platformStyle.background

    return (
      <StyledSvg
        className={iconOptions.className}
        width={iconOptions.size || 24}
        height={iconOptions.size || 24}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        rotate={iconOptions.rotate}
      >
        {iconOptions.title && <title>{iconOptions.title}</title>}
        <SvgContentComponent
          {...iconOptions}
          color={color}
          backgroundColor={backgroundColor}
        />
      </StyledSvg>
    )
  })
}
