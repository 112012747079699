import { Box } from '@mui/material'
import EnumeratedComponent, {
  EnumeratedComponentType,
} from '../EnumeretadComponent/EnumeratedComponent'

export type EnumeratedComponentTypeOnList = Omit<
  EnumeratedComponentType,
  'itemCount'
>

export default function EnumeratedComponentList({
  enumeratedComponentList,
}: {
  enumeratedComponentList: EnumeratedComponentTypeOnList[]
}): JSX.Element {
  return (
    <Box>
      {enumeratedComponentList?.map(
        (item: EnumeratedComponentTypeOnList, index) => (
          <EnumeratedComponent
            key={item.title}
            itemCount={`0${index + 1}`}
            title={item.title}
            subtitle={item.subtitle}
            component={item.component}
          />
        ),
      )}
    </Box>
  )
}
