import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m10,20.5l0,-6l4,0l0,6l5,0l0,-8l3,0l-10,-9l-10,9l3,0l0,8l5,0z"
      />
    </>
  )
}

export const HomeIcon = createIcon(IconContent)
