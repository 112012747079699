import { designPalette, fonts, unmappedColors } from './design-theme-properties'

export const defaultTheme = {
  background: designPalette.primaryLightMode,
  backgroundAlt: designPalette.secondaryLightMode,
  primaryColor: designPalette.font.primaryLightMode,
  secondaryColor: designPalette.font.secondaryLightMode,
  dangerColor: designPalette.accentRed,
  successColor: designPalette.accentGreen,
  dividerColor: unmappedColors.dividerColorLigthMode,
  body2Color: designPalette.font.body2LightMode,
  toast: {
    width: '450px',
    height: '52px',
  },
  typography: {
    fontFamily: fonts.roboto,
    primaryColor: designPalette.font.primaryLightMode,
    secondaryColor: designPalette.font.secondaryDarkMode,
    headers: {
      fontFamily: fonts.canela,
      size: '24px',
    },
    sizes: {
      desktop: {
        small: '12px',
        medium: '16px',
      },
      mobile: {
        small: '10px',
        medium: '14px',
      },
    },
  },
  gallery: {
    background: `${designPalette.primaryLightMode}ee`,
  },
  input: {
    color: designPalette.font.secondaryLightMode,
    borderColor: unmappedColors.inputBorderColorLightMode,
    label: {
      color: designPalette.font.secondaryLightMode,
    },
  },
  select: {
    color: designPalette.font.primaryLightMode,
  },
  backdropColor: 'rgba(250, 250, 255, 0.6)',
  boxShadowColor: 'rgba(0, 0, 0, 0.1)',
  isDark: false,
}
