import { InputBase as MuiInputBase, ThemeProvider } from '@mui/material'
import { InputBaseProps } from '.'
import { theme } from '../../avenue-8-platform-style'

export default function Input(props: InputBaseProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <MuiInputBase {...props} />
    </ThemeProvider>
  )
}
