//Using the same names from our design guidelines
export const designPalette = {
  primaryLightMode: '#FFFFFF',
  secondaryLightMode: '#FAFAFF',
  primaryDarkMode: '#131319',
  secondaryDarkMode: '#1C1D26',
  accentGreen: '#107F10',
  accentRed: '#D90D02',
  font: {
    primaryLightMode: '#131319',
    secondaryLightMode: '#73738C',
    primaryDarkMode: '#FFFFFF',
    secondaryDarkMode: '#8C8EA1',
    body2LightMode: '#2A2A2A',
  },
}

export const unmappedColors = {
  dividerColorLigthMode: '#D5D6EA77',
  dividerColorDarkMode: '#FFFFFF11',
  inputBorderColorLightMode: '#D5D6EA',
  inputBorderColorDarkMode: '#FFFFFF',
}

export const fonts = {
  roboto: 'Roboto',
  canela: 'Canela',
}
