import * as React from 'react'
import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <React.Fragment>
      <path d="M12 4V20" stroke={color} />
      <path d="M4 12L20 12" stroke={color} />
    </React.Fragment>
  )
}

export const PlusIcon = createIcon(IconContent)
