import { Button as MuiButton, ButtonProps, ThemeProvider } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'

export default function Button(props: ButtonProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <MuiButton {...props}/>
    </ThemeProvider>
  )
}
