import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m20.00003,4l-16.00005,0c-1.11,0 -1.99,0.89 -1.99,2l-0.01,12c0,1.11 0.89,2 2,2l16.00005,0c1.11,0 2,-0.89 2,-2l0,-12c0,-1.11 -0.89,-2 -2,-2zm0,14l-16.00005,0l0,-6l16.00005,0l0,6zm0,-10l-16.00005,0l0,-2l16.00005,0l0,2z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </>
  )
}

export const CreditCardIcon = createIcon(IconContent)
