import Typography from '@mui/material/Typography'
import styled from '@emotion/styled'
import { DownloadIcon } from '../Icons'
import { defaultTheme } from '../../theme'

export type ContentReadyType = {
  id: number
  src: string
  title: string
  category: string
  description?: string
  onClick: (id: number) => void
  showIcon?: boolean
  onIconClick?: (id: number) => void
  [k: string]: any
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 155px;
  border-radius: 4px;
  cursor: pointer;
`

const DownloadIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px 10px 7px 10px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  &:hover {
    background-color: #c7d8f9;
  }
`

const InformationContainer = styled.div`
  max-width: 155px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
`

const ConstrainedCardImage = styled.img`
  height: 155px;
  width: 155px;
  border-radius: 4px;
  border: 1px solid #d5d6ea;
  object-fit: contain;
`

export default function ContentCard({
  id: _id,
  src,
  title,
  category,
  showIcon = false,
  description = '',
  onIconClick,
  onClick,
  ...extraProps
}: ContentReadyType): JSX.Element {
  const handleClick = () => {
    if (onClick) onClick(_id)
  }
  const handleIconClick = () => {
    if (onIconClick) onIconClick(_id)
  }

  return (
    <Wrapper {...extraProps}>
      <ConstrainedCardImage src={src} onClick={handleClick} />
      {showIcon
        ? (
            <DownloadIconContainer onClick={handleIconClick}>
              <DownloadIcon size={20} color={defaultTheme.secondaryColor} />
            </DownloadIconContainer>
          )
          : null
      }
      <InformationContainer onClick={handleClick}>
        <Typography fontSize={14} variant="body1" fontWeight="300">
          {title}
        </Typography>
        <Typography
          fontSize={10}
          variant="caption"
          color="textSecondary"
          fontWeight="300"
        >
          {category}
        </Typography>
      </InformationContainer>
    </Wrapper>
  )
}
