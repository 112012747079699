import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C', backgroundColor = '#FFFFFF' }: IconOptions) {
  return (
    <>
      <rect width="24" height="24" fill={backgroundColor}/>
      <rect x="4.5" y="4.5" width="15" height="15" rx="1.5" stroke="#C4C4C4"/>
      <path d="M18 9L10.9289 16.0711L7.3934 12.5355" stroke={color}/>
      <rect x="4.5" y="4.5" width="15" height="15" rx="1.5" stroke="#C4C4C4"/>
    </>
  )
}

export const CheckboxIcon = createIcon(IconContent)
