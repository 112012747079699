import styled from '@emotion/styled'

const Stepper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding: 1rem 0;
  gap: 1rem;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
  }
`

export default Stepper
