import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button, ButtonProps, Box, ThemeProvider } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'

const RoundedButton = styled(Button)`
  &.MuiButton-root {
    height: 48px;
    width: 48px;
    min-width: 48px;
    border: 1px solid #d5d6ea;
    border-radius: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #fafaff;
    }
  }
`

type PaginationButtonType = {
  onBackClick?: () => void
  backDisabled: boolean
  backButtonProps?: ButtonProps
  onNextClick?: () => void
  nextDisabled: boolean
  nextButtonProps?: ButtonProps
  middleChildren?: JSX.Element
}

export default function PaginationButton({
  onBackClick,
  backDisabled,
  backButtonProps = {},
  onNextClick,
  nextDisabled,
  nextButtonProps = {},
  middleChildren = null,
}: PaginationButtonType): JSX.Element {
  return (
    <Box display="flex" alignItems="center">
      <ThemeProvider theme={theme}>
        <RoundedButton
          {...backButtonProps}
          onClick={() => onBackClick?.()}
          disabled={backDisabled}
          sx={{ marginRight: 2 }}
        >
          <ChevronLeft />
        </RoundedButton>
        {middleChildren}
        <RoundedButton
          {...nextButtonProps}
          onClick={() => onNextClick?.()}
          disabled={nextDisabled}
          sx={{ marginLeft: 2 }}
        >
          <ChevronRight />
        </RoundedButton>
      </ThemeProvider>
    </Box>
  )
}
