import * as React from 'react'
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles'
import { muiDarkTheme, muiDefaultTheme } from '../../theme/material-themes'
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'

export interface ThemeProviderProps {
  children: React.ReactNode
  darkMode?: boolean
}

const ThemeProvider = ({
  children,
  darkMode = false,
}: ThemeProviderProps): JSX.Element => {
  const forceDarkMode =
    typeof window !== 'undefined' && /forcedark=1/.test(window.location.search)
  const isDarkTheme = forceDarkMode || darkMode
  const muiTheme = isDarkTheme ? muiDarkTheme : muiDefaultTheme

  return (
    <StyledEngineProvider injectFirst>
      <EmotionThemeProvider theme={muiTheme}>
        <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
      </EmotionThemeProvider>
    </StyledEngineProvider>
  )
}

export default ThemeProvider
