import { ImageIcon } from '../Icons/ImageIcon'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import ImageUnavailableProps from './ImageUnavailableProps'
import { theme } from '../../avenue-8-platform-style'

const ImageUnavailableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${(p) => p.theme.platformStyle.backgroundAlt};
  height: 100%;
  width: 100%;
  color: ${(p) => (p.color ? p.color : p.theme.platformStyle.secondaryColor)};
  letter-spacing: 2px;
`

const Message = styled(Typography)`
  text-transform: uppercase;
  margin-top: 0.5rem;
  text-align: center;
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.mobile.small};
`

export default function ImageUnavailable(
  props: ImageUnavailableProps,
): JSX.Element {
  const {
    message = 'Image Unavailable',
    color = theme.platformStyle.secondaryColor,
    ...rest
  } = props
  return (
    <ImageUnavailableContainer {...rest}>
      <ImageIcon color={color} />
      <Message>{message}</Message>
    </ImageUnavailableContainer>
  )
}
