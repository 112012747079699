import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <rect x="3" y="5" width="17" height="2" fill={color}/>
      <rect x="5.5" y="3.5" width="12" height="16" rx="1.5" stroke={color}/>
      <rect x="8" y="15" width="6" height="1" transform="rotate(-90 8 15)" fill={color}/>
      <rect x="11" y="15" width="6" height="1" transform="rotate(-90 11 15)" fill={color}/>
      <rect x="14" y="15" width="6" height="1" transform="rotate(-90 14 15)" fill={color}/>
      <rect x="6" y="4" width="11" height="1" fill={color}/>
    </>
  )
}

export const DeleteIcon = createIcon(IconContent)
