import { useState } from 'react'
import styled from '@emotion/styled'
import { defaultTheme } from '../../avenue-8-platform-style'
import {
  Box,
  TextareaAutosize,
  TextareaAutosizeProps,
  Typography,
} from '@mui/material'

const Wrapper = styled(Box)<{ $hasCharCounter: boolean; $error: boolean }>`
  position: relative;
  display: flex;

  textarea {
    background-color: ${(p) => defaultTheme.background};
    border: 1px solid
      ${(p) =>
        p.$error ? defaultTheme.dangerColor : defaultTheme.input.borderColor};
  }

  textarea:focus {
    background-color: ${(p) => defaultTheme.backgroundAlt};
    border: 2px solid
      ${(p) =>
        p.$error ? defaultTheme.dangerColor : defaultTheme.input.borderColor};
    padding: 15px;
    padding-bottom: ${(p) => (p.$hasCharCounter ? '31px' : '15px')};
  }

  textarea ~ .MuiTypography-root {
    background-color: ${(p) => defaultTheme.background};
    bottom: 1px;
  }

  textarea:focus ~ .MuiTypography-root {
    background-color: ${(p) => defaultTheme.backgroundAlt};
    bottom: 2px;
  }
`

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  max-width: 100%;
  color: ${(p) => defaultTheme.input.color};
  border-radius: 4px;
  width: 100%;
  resize: vertical;
  margin: 0;
  padding: 16px;
  box-sizing: border-box;
  font-family: ${(p) => defaultTheme.typography.fontFamily};
  font-size: 14px;
  outline: none;

  &::placeholder {
    opacity: 0.4;
  }
`

const CharCounter = styled(Typography)`
  position: absolute;
  left: 16px;
  letter-spacing: 2px;
  color: ${(p) => defaultTheme.input.color};
  font-size: 10px;
  width: calc(100% - 32px);
  padding-bottom: 8px;
  padding-top: 4px;
`

const HelperText = styled(Typography)<{ $error: boolean }>`
  color: ${(p) =>
    p.$error ? defaultTheme.dangerColor : defaultTheme.primaryColor};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  width: 100%;
  margin-top: 3px;
  font-weight: 400;
`

interface TextareaProps extends TextareaAutosizeProps {
  maxCharLength?: number
  error?: boolean
  helperText?: string
}

export default function Textarea({
  maxCharLength,
  error = false,
  helperText = '',
  ...props
}: TextareaProps): JSX.Element {
  const [charCounter, setCharCounter] = useState(0)

  return (
    <Box display="flex" flexDirection="column">
      <Wrapper $hasCharCounter={!!maxCharLength} $error={error}>
        <StyledTextarea
          {...props}
          onChange={(e) => {
            setCharCounter(e.target.value.length)
            !!props.onChange && props.onChange(e)
          }}
        />
        {!!maxCharLength && (
          <CharCounter>
            {charCounter}/{maxCharLength}
          </CharCounter>
        )}
      </Wrapper>
      {helperText ? <HelperText $error={error}>{helperText}</HelperText> : null}
    </Box>
  )
}
