import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#131319' }: IconOptions) {
  return (
    <>
      <path
        stroke={color}
        fill={color}
        strokeWidth="0.1px"
        d="m17.50617,9.18957c0.11874,0.16141 0.17455,0.36427 0.15622,0.56737c-0.01845,0.20311 -0.10968,0.39124 -0.25536,0.52617c-0.14556,0.13493 -0.33467,0.20657 -0.52871,0.20034c-0.19404,-0.00623 -0.37845,-0.08985 -0.51599,-0.23387l-4.04431,-4.25043l-4.04428,4.25043l-0.07183,0.06666c-0.15357,0.12479 -0.34659,0.18349 -0.53985,0.16413c-0.19325,-0.01935 -0.37225,-0.11528 -0.50064,-0.2683c-0.1284,-0.15304 -0.19655,-0.35169 -0.19062,-0.55562c0.00593,-0.20393 0.08548,-0.39784 0.22252,-0.54235l4.5845,-4.81818l0.07183,-0.06666c0.14701,-0.11985 0.33058,-0.17921 0.51627,-0.16694c0.1857,0.01227 0.36074,0.09533 0.49232,0.2336l4.58454,4.81818l0.06338,0.07548zm-10.37625,5.80266c-0.11875,-0.16141 -0.17459,-0.36425 -0.15618,-0.56732c0.01841,-0.20311 0.10968,-0.39124 0.2553,-0.52617c0.1456,-0.13493 0.33462,-0.20657 0.52866,-0.20034c0.19404,0.00623 0.37854,0.08985 0.51605,0.23387l4.04428,4.25043l4.04431,-4.25043l0.07186,-0.06666c0.15358,-0.12479 0.34659,-0.18349 0.53982,-0.16413c0.19324,0.01935 0.37226,0.11528 0.50063,0.2683c0.12837,0.15304 0.19656,0.35169 0.1906,0.55556c-0.00596,0.20393 -0.0855,0.39786 -0.22246,0.54241l-4.58454,4.81818l-0.07183,0.06661c-0.14701,0.11985 -0.33059,0.17924 -0.51627,0.16695c-0.1857,-0.01229 -0.36074,-0.09528 -0.49232,-0.23356l-4.5845,-4.81818l-0.06342,-0.07553l0,0z"
      />
    </>
  )
}

export const VerticalChevronIcon = createIcon(IconContent)
