/* eslint-disable multiline-ternary */
import {
  Box,
  ModalUnstyled,
  Backdrop,
  Slide,
  useMediaQuery,
  ThemeProvider,
  ModalUnstyledProps,
} from '@mui/material'
import styled from '@emotion/styled'
import { theme } from '../../avenue-8-platform-style'
import FinalizeDesktopView from './FinalizeDesktopView'
import FinalizeMobileView from './FinalizeMobileView'
import { EnumeratedComponentTypeOnList } from '../EnumeratedComponentList/EnumeratedComponentList'
import { SxProps } from '@mui/system'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 125px);
  height: 100vh;
  outline: none;

  @media (max-width: 641px) {
    width: 100%;
  }
`
const BackdropStyled = styled(Backdrop)`
  z-index: -1;
`
const Wrapper = styled(Box)`
  box-shadow: 1px 4px 40px rgba(0, 0, 0, 0.25);
  display: flex;
  border-radius: 20px 0 0 20px;
  background-color: white;
  width: 100%;
  height: 100vh;
  outline: none;

  @media (max-width: 641px) {
    flex-direction: column;
    border-radius: 20px 20px 0 0;
  }
`

export type FinalizeDialogType = {
  open: boolean
  title: string
  titleStyle?: SxProps
  totalPrice: string
  leftSideBarFirstSlot: JSX.Element
  leftSideBarSecondSlot?: JSX.Element
  mobileSlot?: JSX.Element
  mobileSubmitSlot: JSX.Element
  enumeratedComponentList?: EnumeratedComponentTypeOnList[]
  handleClose: () => void
  modalProps?: Partial<ModalUnstyledProps>
}

export default function FinalizeDialog({
  open,
  totalPrice,
  title,
  titleStyle = {},
  leftSideBarFirstSlot,
  leftSideBarSecondSlot,
  mobileSlot,
  mobileSubmitSlot,
  enumeratedComponentList,
  handleClose,
  modalProps,
}: FinalizeDialogType): JSX.Element {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <ThemeProvider theme={theme}>
      <StyledModal
        aria-labelledby="finalize-dialog"
        aria-describedby="finalize-dialog-content"
        open={open}
        onClose={handleClose}
        BackdropComponent={BackdropStyled}
        {...modalProps}
      >
        <Slide
          in={open}
          direction={isDesktop ? 'left' : 'up'}
          timeout={{ enter: 400, exit: 400 }}
        >
          <Wrapper>
            {isDesktop ? (
              <FinalizeDesktopView
                title={title}
                titleStyle={titleStyle}
                leftSideBarFirstSlot={leftSideBarFirstSlot}
                leftSideBarSecondSlot={leftSideBarSecondSlot}
                enumeratedComponentList={enumeratedComponentList}
                handleClose={handleClose}
              />
            ) : (
              <FinalizeMobileView
                title={title}
                totalPrice={totalPrice || '0.00'}
                mobileSlot={mobileSlot}
                mobileSubmitSlot={mobileSubmitSlot}
                enumeratedComponentList={enumeratedComponentList}
                handleClose={handleClose}
              />
            )}
          </Wrapper>
        </Slide>
      </StyledModal>
    </ThemeProvider>
  )
}
