import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { defaultTheme } from '../../theme'

const EnumerationTitleContainer = styled(Box)`
  display: flex;
`
const ItemBody = styled(Box)`
  padding: 10px 0 10px 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
`
const Line = styled.hr`
  border: none;
  width: 100%;
  height: 1px;
  background-color: ${() => defaultTheme.typography.primaryColor};
`

export type EnumeratedComponentType = {
  itemCount: string
  title: string
  titleStyle?: SxProps
  subtitle: string
  component: JSX.Element
}

export default function EnumeratedComponent({
  itemCount,
  title,
  titleStyle = {},
  subtitle,
  component,
}: EnumeratedComponentType): JSX.Element {
  return (
    <Box>
      <EnumerationTitleContainer>
        <Box mr={3}>
          <Typography
            color="primary"
            fontFamily="Canela"
            lineHeight={1}
            fontSize="16px"
          >
            {itemCount.padStart(2, '0') || ''}
          </Typography>
        </Box>
        <Box flexShrink={0} mr={1}>
          <Typography
            fontSize={12}
            fontFamily="Roboto"
            fontWeight="700"
            color="primary"
            letterSpacing={2}
            sx={{ ...titleStyle }}
          >
            {title || ''}
          </Typography>
        </Box>
        <Line />
      </EnumerationTitleContainer>
      <ItemBody>
        <Typography
          fontSize="16px"
          variant="body2"
          fontWeight={400}
          color="secondary"
        >
          {subtitle || ''}
        </Typography>
        <Box>{component}</Box>
      </ItemBody>
    </Box>
  )
}
