import React from 'react'
import { Grid, Typography } from '@mui/material'
import { useScreenSize } from '../../hooks'

type SectionContainerType = {
  title: string
  rightActionButton?: JSX.Element
  tooltip?: JSX.Element
  children: React.ReactNode
}
export default function SectionContainer({
  title = 'Section Title',
  rightActionButton,
  tooltip,
  children,
}: SectionContainerType): JSX.Element {
  const { isMobile, isTablet } = useScreenSize()

  const hasTooltipAndIsMobile = !!tooltip && (isMobile || isTablet)

  return (
    <Grid overflow="hidden">
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography
          sx={{ fontSize: { xs: 20, md: 24 } }}
          fontFamily="Canela"
          variant="body1"
          color="#2A2A2A"
        >
          {title}
          {hasTooltipAndIsMobile && (
            <span style={{ marginLeft: '4px' }}>{tooltip}</span>
          )}
        </Typography>
        {rightActionButton && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={2}
          >
            {rightActionButton}
          </Grid>
        )}
      </Grid>
      <Grid
        sx={{ paddingLeft: { xs: 0, sm: 2 }, paddingTop: { xs: 1, sm: 2 } }}
      >
        {children}
      </Grid>
    </Grid>
  )
}
