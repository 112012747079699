import React from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useScreenSize } from '../../hooks'
import { defaultTheme } from '../../theme'
import { DatePicker } from './DatePicker'
import { isSameDay, isBefore } from 'date-fns'
import { SxProps } from '@mui/system'

const PickersContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextSeparator = styled(Typography)`
  font-family: Roboto, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 2px;
  color: ${defaultTheme.secondaryColor};
  margin-top: 20px;
`

export interface DateRangePickerProps {
  value?: { startDate?: Date; endDate?: Date }
  minStartDate?: Date
  maxEndDate?: Date
  startSx?: SxProps
  endSx?: SxProps
  cb: (startDate: Date, endDate: Date) => void
  disabled: boolean
}

const DateRangePicker = ({
  value: { startDate: start = null, endDate: end = null } = {},
  minStartDate = null,
  maxEndDate = null,
  startSx,
  endSx,
  cb,
  disabled,
}: DateRangePickerProps): JSX.Element => {
  const { isDesktop } = useScreenSize()
  const [startDate, setStartDate] = React.useState<Date | null>(start ?? null)
  const [endDate, setEndDate] = React.useState<Date | null>(end ?? null)

  const [minEndDate, setMinEndDate] = React.useState<Date | null>(
    minStartDate ?? startDate ?? null,
  )

  if (minStartDate) {
    if (isBefore(maxEndDate, minStartDate)) {
      throw new Error('maxEndDate should not be previous to minStartDate')
    }
  } else {
    if (isSameDay(maxEndDate, new Date())) {
      throw new Error('maxEndDate should not be previous to today')
    }
  }

  const handleStartDateChange = (value: Date) => {
    setStartDate(value)
    setMinEndDate(value)
  }

  React.useEffect(() => {
    if (!startDate || !endDate) {
      return
    }

    if (isBefore(endDate, startDate)) {
      return
    }

    if (isSameDay(startDate, start) && isSameDay(endDate, end)) {
      return
    }

    cb(startDate, endDate)
  }, [cb, startDate, endDate, start, end])

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PickersContainer
          container
          flexDirection={'row'}
          columnSpacing={2}
          spacing={isDesktop ? 1 : 0}
        >
          <Grid item xs={12} lg='auto'>
            <DatePicker
              title="START DATE"
              value={startDate}
              minDate={minStartDate}
              maxDate={maxEndDate}
              setValueHandler={handleStartDateChange}
              disabled={disabled}
              sx={startSx}
            />
          </Grid>
          {isDesktop && (
            <Grid item>
              <TextSeparator>TO</TextSeparator>
            </Grid>
          )}
          <Grid item xs={12} lg='auto'>
            <DatePicker
              title="END DATE"
              value={endDate}
              setValueHandler={setEndDate}
              minDate={minEndDate}
              maxDate={maxEndDate}
              disabled={disabled}
              sx={endSx}
            />
          </Grid>
        </PickersContainer>
      </LocalizationProvider>
    </div>
  )
}

export default DateRangePicker
