import { SpreadsheetIcon } from '../Icons/SpreadsheetIcon'
import { DeleteIcon } from '../Icons/DeleteIcon'
import styled from '@emotion/styled'
import { Box, IconButton, Typography } from '@mui/material'

type UploadedFileProps = {
  name: string
  icon: JSX.Element
  onDelete?: () => void
  percentage?: number
}

const Container = styled(Box)`
  display: flex;
  width: 100%;
`

const BarWithFilenameBox = styled(Box)<{ isBarShowing?: boolean }>`
  margin-left: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isBarShowing }) =>
    isBarShowing ? 'flex-start' : 'center'};
  margin-bottom: ${({ isBarShowing }) => (isBarShowing ? '0' : '1rem')};
`

const Bar = styled(Box)<{ percentage?: number }>`
  background: #d5d6ea;
  border-radius: 0.8rem;
  height: 0.25rem;
  width: 260px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percentage }) => (percentage <= 100 ? percentage : 100)}%;
    height: 100%;
    background-color: #8c8ea1;
    border-radius: 1rem;
  }
`

const DeleteIconButton = styled(IconButton)`
  position: relative;
  top: -0.25rem;
`

const truncateLongFileName = (name: string, maxlength = 25) => {
  if (name.length <= maxlength) return name

  const regex = /(?<name>.+)\.(?<extension>.+)/gi
  const { groups } = regex.exec(name)
  const fileName = groups.name
  const fileExtension = groups.extension
  // prettier-ignore
  return `${fileName.slice(0, 10)}...${fileName.slice(fileName.length - 10)}.${fileExtension}`
}

export default function UploadProgressBar({
  name,
  onDelete,
  percentage,
  icon,
}: UploadedFileProps): JSX.Element {
  const shouldBarAppear = percentage >= 0 && percentage < 100

  return (
    <Container>
      {icon ?? <SpreadsheetIcon color="#73738C" />}
      <BarWithFilenameBox isBarShowing={shouldBarAppear}>
        <Typography
          sx={{
            fontSize: '0.8rem',
            lineHeight: '0.8rem',
            fontWeight: 400,
            marginBottom: shouldBarAppear ? '0.2rem' : '0',
          }}
          variant="body2"
        >
          {truncateLongFileName(name)}
        </Typography>
        {shouldBarAppear && <Bar percentage={percentage} />}
      </BarWithFilenameBox>

      <DeleteIconButton onClick={onDelete}>
        <DeleteIcon color="#73738C" />
      </DeleteIconButton>
    </Container>
  )
}
