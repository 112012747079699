import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m17.10705,5.2l-3.04963,0c-0.30642,-0.986 -1.10896,-1.7 -2.05742,-1.7c-0.94846,0 -1.751,0.714 -2.05743,1.7l-3.04965,0c-0.80254,0 -1.45917,0.765 -1.45917,1.7l0,11.9c0,0.935 0.65663,1.7 1.45917,1.7l10.21413,0c0.8026,0 1.4592,-0.765 1.4592,-1.7l0,-11.9c0,-0.935 -0.6566,-1.7 -1.4592,-1.7zm-5.10687,0.00006c0.40127,0 0.72958,0.3825 0.72958,0.85c0,0.4675 -0.32831,0.85 -0.72958,0.85c-0.40127,0 -0.72958,-0.3825 -0.72958,-0.85c0,-0.4675 0.32831,-0.85 0.72958,-0.85zm1.45828,11.89984l-5.10708,0l0,-1.7l5.10708,0l0,1.7zm2.18879,-3.4001l-7.29587,0l0,-1.70001l7.29587,0l0,1.70001zm0,-3.39964l-7.29587,0l0,-1.7l7.29587,0l0,1.7z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </>
  )
}

export const NoteBoardIcon = createIcon(IconContent)
