import React, { useMemo } from 'react'
import { CardActionArea } from '@mui/material'
import {
  CustomCard,
  ConstrainedCardMediaSmall,
  ConstrainedCardMediaMedium,
  TitleMedium,
  TitleSmall,
  BottomInfo,
  TextLineMedium,
  TextLineSmall,
  CustomCardContent,
  CardTag,
  CardTagInnerText,
} from './PropertyCard.styles'
import { ImageUnavailable } from '../ImageUnavailable'
import PropertyCardProps from './PropertyCardProps'

const PropertyCard = ({
  disabled = false,
  imageUrl,
  title,
  content,
  cardProps,
  label,
  labelAction,
  labelIcon,
  size = 'small',
}: PropertyCardProps): JSX.Element => {
  const noPropagateOnClickLabel = (e: any) => {
    e.stopPropagation()
    !disabled && labelAction?.(e)
  }

  const Title = useMemo(
    () => (size === 'medium' ? TitleMedium : TitleSmall),
    [size],
  )
  const TextLine = useMemo(
    () => (size === 'medium' ? TextLineMedium : TextLineSmall),
    [size],
  )
  const ConstrainedCardMedia = useMemo(
    () =>
      size === 'medium'
        ? ConstrainedCardMediaMedium
        : ConstrainedCardMediaSmall,
    [size],
  )

  return (
    <CustomCard
      disabled={disabled}
      elevation={0}
      {...cardProps}
      data-testid="property-card"
    >
      <CardActionArea>
        <ConstrainedCardMedia image={imageUrl} title={title}>
          {!imageUrl && <ImageUnavailable />}
          {!!label && (
            <CardTag onClick={noPropagateOnClickLabel}>
              {!!labelIcon && labelIcon()}
              <CardTagInnerText variant="body2" color="textSecondary">
                {label}
              </CardTagInnerText>
            </CardTag>
          )}
        </ConstrainedCardMedia>
        <CustomCardContent>
          <Title variant="body1" data-testid="property-title">
            {title}
          </Title>
          {typeof content === 'string' && (
            <BottomInfo>
              <TextLine variant="body2">{content}</TextLine>
            </BottomInfo>
          )}
          {typeof content !== 'string' && content}
        </CustomCardContent>
      </CardActionArea>
    </CustomCard>
  )
}

export default PropertyCard
