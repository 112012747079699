import { Breakpoint, useMediaQuery } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'

type MediaMatchType = {
  size: Breakpoint
  reference: 'down' | 'up'
  children: JSX.Element
}

export default function MediaMatch({
  size,
  reference,
  children,
}: MediaMatchType): JSX.Element {
  const matches = useMediaQuery(theme.breakpoints[reference](size))

  return <>{matches && children}</>
}
