import { Grid, Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { CloseIcon } from '../Icons'
import { EnumeratedComponent } from '../EnumeretadComponent'
import { EnumeratedComponentTypeOnList } from '../EnumeratedComponentList/EnumeratedComponentList'
import { defaultTheme } from '../../theme'
import { SxProps } from '@mui/system'

const LeftColumn = styled(Box)`
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  background: ${() => defaultTheme.backgroundAlt};
  border-radius: 20px 0 0 20px;
  padding: 60px 45px;
  max-width: 275px;
  @media (max-width: 1007px) {
    max-width: 200px;
  }
`

const LeftColumnItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 30px;
  height: 100%;
`
const LeftSideBarFirstSlotContainer = styled(Box)``
const LeftSideBarSecondSlotContainer = styled(Box)`
  @media (max-width: 641px) {
    display: none;
  }
`

const RightColumn = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 25px;
  overflow: scroll;
`

const RightColumnBody = styled(Box)`
  margin: 10px 30px;
`

type FinalizeDesktopViewType = {
  title: string
  titleStyle?: SxProps
  leftSideBarFirstSlot: JSX.Element
  leftSideBarSecondSlot: JSX.Element
  enumeratedComponentList: EnumeratedComponentTypeOnList[]
  handleClose: () => void
}

export default function FinalizeDesktopView({
  title,
  titleStyle = {},
  leftSideBarFirstSlot,
  leftSideBarSecondSlot,
  enumeratedComponentList,
  handleClose,
}: FinalizeDesktopViewType): JSX.Element {
  return (
    <>
      <LeftColumn>
        <Typography variant="h2" component="h2">
          {title}
        </Typography>
        <LeftColumnItemsContainer>
          <LeftSideBarFirstSlotContainer>
            {leftSideBarFirstSlot}
          </LeftSideBarFirstSlotContainer>
          {leftSideBarSecondSlot && (
            <LeftSideBarSecondSlotContainer>
              {leftSideBarSecondSlot}
            </LeftSideBarSecondSlotContainer>
          )}
        </LeftColumnItemsContainer>
      </LeftColumn>
      <RightColumn>
        <Grid container justifyContent="flex-end">
          <span style={{ cursor: 'pointer' }} onClick={handleClose}>
            <CloseIcon />
          </span>
        </Grid>
        <RightColumnBody>
          {enumeratedComponentList.map((enumeratedComponent, index) => (
            <EnumeratedComponent
              key={enumeratedComponent.title}
              itemCount={`${index + 1}`}
              title={enumeratedComponent.title}
              titleStyle={titleStyle}
              subtitle={enumeratedComponent.subtitle}
              component={enumeratedComponent.component}
            />
          ))}
        </RightColumnBody>
      </RightColumn>
    </>
  )
}
