import React from 'react'
import { Grid, Typography, TypographyProps } from '@mui/material'
import { CloseIcon } from '../Icons'

export type DialogHeaderType = {
  title: string
  subtitle?: string
  onCancel: () => void
  titleProps?: TypographyProps<'h2', { component?: 'h2' }>
}

export default function DialogHeader({
  title,
  onCancel,
  subtitle,
  titleProps,
}: DialogHeaderType): JSX.Element {
  return (
    <Grid container justifyContent="space-between" position="relative">
      <Grid item xs={8} container direction="column">
        <Typography
          fontSize={24}
          fontFamily="Canela"
          fontWeight={300}
          component="h2"
          {...titleProps}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body2"
            color="#8C8EA1"
            fontWeight={300}
            marginTop="8px"
          >
            {subtitle}
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={1}
        onClick={onCancel}
        position="absolute"
        right="0"
        top="5px"
        style={{ cursor: 'pointer' }}
      >
        <CloseIcon size={20} />
      </Grid>
    </Grid>
  )
}
