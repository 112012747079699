import SwiperCore, { Pagination, Autoplay, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'
import styled from '@emotion/styled'

interface Props {
  children: JSX.Element[]
  settings?: SwiperOptions
}
SwiperCore.use([Autoplay, Pagination])

const GalleryContainer = styled.div`
  & > .swiper-container {
    overflow: visible;
    padding-bottom: 40px;
  }

  & .swiper-pagination-bullet {
    margin: 0 16px !important;
  }

  & .swiper-pagination-bullet-active {
    background-color: #73738c;
  }

  & #galleryIcon {
    position: absolute;
    top: 10px;
    right: 10px;
  }
`

const defaultSettings: SwiperOptions = {
  spaceBetween: 20,
  slidesPerView: 'auto',
  pagination: { clickable: true },
  centeredSlides: true,
  mousewheel: true,
  breakpoints: {
    300: {
      slidesPerView: 'auto',
    },
    768: {
      slidesPerView: 1,
    },
  },
}

export default function Slider({
  children,
  settings,
}: Props): JSX.Element {
  const swiperSettings = { ...defaultSettings, ...settings }
  return (
    <GalleryContainer>
      <Swiper {...swiperSettings}>
        {children.map((element, index) => {
          return <SwiperSlide key={index}>{element}</SwiperSlide>
        })}
      </Swiper>
    </GalleryContainer>
  )
}
