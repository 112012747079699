/* eslint-disable camelcase */
import React from 'react'
import { useMediaQuery } from '@mui/material'
import { theme } from '../avenue-8-platform-style'

export type useScreenSizeBreakpoints = 'isMobile' | 'isTablet' | 'isSmallScreen' | 'isSmallDesktop' | 'isDesktop' | 'isLargeDesktop'

export default function useScreenSize(): Record<useScreenSizeBreakpoints, boolean> {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.up('sm') && theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up('md') && theme.breakpoints.down('lg'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
  const isLargeDesktop = useMediaQuery(theme.breakpoints.up('xl'))

  return { isMobile, isTablet, isDesktop, isLargeDesktop, isSmallScreen, isSmallDesktop }
}
