import * as React from 'react'
import usePagination from '@mui/material/usePagination'
import { Button } from '../../components/Button'
import styled from '@emotion/styled'
import { ChevronIcon } from '../Icons/ChevronIcon'
import PaginationProps from './PaginationProps'

const PageNumbersContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  & button {
    font-weight: 400;
  }
`

type NumberButtonProps = {
  $active?: boolean
  $size?: PaginationProps['size']
}

const NumberButton = styled(Button)<NumberButtonProps>`
  background: ${(p) =>
    p.$active ? '#f3f3fa' : p.theme.platformStyle.background};
  border-radius: 4px;
  border: ${(p) =>
    p.$active
      ? `solid 1px ${p.theme.platformStyle.primaryColor}`
      : `solid 1px ${p.theme.platformStyle.input.borderColor}`};
  width: initial;
  max-width: initial;
  padding: 0.5rem;
  min-width: 32px;
  height: 32px;
  ${(p) => (p.$size === 'small' ? 'margin: 0 0.25rem;' : 'margin: 0 0.5rem;')}
`

const NavigationButton = styled(Button)<NumberButtonProps>`
  min-width: initial;
  min-height: initial;
  ${(p) => (p.$size === 'small' ? 'width: 40px;' : 'width: 48px;')}
  ${(p) => (p.$size === 'small' ? 'height: 40px;' : 'height: 48px;')}
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${(p) => `1px solid ${p.theme.platformStyle.input.borderColor}`};
  background-color: ${(p) => p.theme.platformStyle.background};
  padding: 0;
  &:hover {
    background: ${(p) => p.theme.platformStyle.backgroundAlt};
  }
`

const Pagination = React.memo((props: PaginationProps) => {
  const {
    currentPage,
    totalPages,
    onPageClick,
    size = 'medium',
    boundaryCount = 1,
    siblingCount = 1,
  } = props

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (!value) return
    onPageClick(value)
  }

  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
    onChange: handlePageChange,
    boundaryCount,
    siblingCount,
  })

  const previous = items.find((item) => item.type === 'previous')
  const next = items.find((item) => item.type === 'next')

  return (
    <ButtonContainer>
      <NavigationButton
        disabled={previous.disabled}
        onClick={previous.onClick}
        $size={size}
      >
        <ChevronIcon rotate={180} size={size === 'small' ? 20 : 24} />
      </NavigationButton>

      <PageNumbersContainer>
        {items.map(({ page, type, selected, ...item }) => {
          if (type === 'page') {
            return (
              <NumberButton
                key={`page-${page}`}
                $active={selected}
                $size={size}
                {...item}
              >
                {page}
              </NumberButton>
            )
          }
          return null
        })}
      </PageNumbersContainer>

      <NavigationButton
        disabled={next.disabled}
        onClick={next.onClick}
        $size={size}
      >
        <ChevronIcon size={size === 'small' ? 20 : 24} />
      </NavigationButton>
    </ButtonContainer>
  )
})

export default Pagination
