import {
  CalendarIcon,
  CheckboxIcon,
  CreditCardIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  FilterIcon,
  LogOutIcon,
  SpreadsheetIcon,
  VerticalChevronIcon,
  NoteBoardIcon,
  GridIcon,
  BurstModeIcon,
  HomeIcon,
  ShareOutlineIcon,
} from './index'
import { IconOptions } from './createIcon'
import { NamedExoticComponent, useMemo } from 'react'
import { RadioIcon } from './RadioIcon'

export type PlatformIconNames =
  | 'calendar'
  | 'checkbox'
  | 'close'
  | 'credit-card'
  | 'delete'
  | 'download'
  | 'filter'
  | 'logout'
  | 'spreadsheet'
  | 'vertical-chevron'
  | 'note-board'
  | 'grid'
  | 'burst-mode'
  | 'home'
  | 'radio'
  | 'share-outline'

interface PlatformIconProps extends IconOptions {
  name: PlatformIconNames
}

export default function PlatformIcon({
  name,
  ...props
}: PlatformIconProps): JSX.Element {
  const PlatformIconsMap = useMemo<
    Record<
      PlatformIconNames,
      NamedExoticComponent<JSX.IntrinsicElements['svg']>
    >
  >(
    () => ({
      calendar: CalendarIcon,
      checkbox: CheckboxIcon,
      close: CloseIcon,
      'credit-card': CreditCardIcon,
      delete: DeleteIcon,
      download: DownloadIcon,
      filter: FilterIcon,
      logout: LogOutIcon,
      spreadsheet: SpreadsheetIcon,
      'vertical-chevron': VerticalChevronIcon,
      'note-board': NoteBoardIcon,
      grid: GridIcon,
      'burst-mode': BurstModeIcon,
      home: HomeIcon,
      radio: RadioIcon,
      'share-outline': ShareOutlineIcon,
    }),
    [],
  )

  const IconComponent = PlatformIconsMap[name]
  return <IconComponent {...props} />
}
