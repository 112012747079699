import { SpreadsheetIcon } from '../Icons/SpreadsheetIcon'
import { DeleteIcon } from '../Icons/DeleteIcon'
import styled from '@emotion/styled'
import { Box, IconButton, Typography } from '@mui/material'

type UploadedFileProps = {
  name: string
  onDelete?: () => void
  percentage?: number
}

const Container = styled(Box)`
  display: flex;
  width: 100%;
  padding: 20px 0 0;
`

const BarWithFilenameBox = styled(Box)<{ isBarShowing?: boolean }>`
  margin-left: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isBarShowing }) =>
    isBarShowing ? 'flex-start' : 'center'};
  margin-bottom: ${({ isBarShowing }) => (isBarShowing ? '0' : '13px')};
`

const Bar = styled(Box)<{ percentage?: number }>`
  background: #d5d6ea;
  border-radius: 10px;
  height: 4px;
  width: 260px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ percentage }) => (percentage <= 100 ? percentage : 100)}%;
    height: 100%;
    background-color: #8c8ea1;
    border-radius: 10px;
  }
`

const DeleteIconButton = styled(IconButton)`
  position: relative;
  top: -5px;
`

export default function UploadProgressBar({
  name,
  onDelete,
  percentage,
}: UploadedFileProps): JSX.Element {
  const shouldBarAppear = percentage >= 0 && percentage < 100

  return (
    <Container>
      <SpreadsheetIcon color="#73738C" />
      <BarWithFilenameBox isBarShowing={shouldBarAppear}>
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: 400,
            marginBottom: shouldBarAppear ? '5px' : '0',
          }}
          variant="body2"
        >
          {name}
        </Typography>
        {shouldBarAppear && <Bar percentage={percentage} />}
      </BarWithFilenameBox>

      <DeleteIconButton onClick={onDelete}>
        <DeleteIcon color="#73738C" />
      </DeleteIconButton>
    </Container>
  )
}
