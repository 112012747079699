import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C', size }: IconOptions) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.6207" cy="10.6207" r="6.12069" stroke={color} />
      <path d="M15.0347 14.483L20.0002 19.4485" stroke={color} />
    </svg>
  )
}

export const SearchIcon = createIcon(IconContent)
