import styled from '@emotion/styled'
import { InputLabel as MuiInputLabel, InputLabelProps as MuiInputLabelProps, ThemeProvider } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'

interface InputLabelProps extends MuiInputLabelProps {
  tooltip?: JSX.Element
}

const Wrapper = styled.div<{ $hasTooltip: boolean }>`
  display: flex;
  align-items: center;
  padding-bottom: ${p => p.$hasTooltip ? '3px' : '0'};
`

export default function InputLabel(props: InputLabelProps): JSX.Element {
  const { tooltip = null } = props
  return (
    <ThemeProvider theme={theme}>
      <Wrapper $hasTooltip={!!tooltip}>
        <MuiInputLabel
          {...props}
          sx={{ marginBottom: `${tooltip ? 0 : 1} !important` }}
        />
        {tooltip}
      </Wrapper>
    </ThemeProvider>
  )
}
