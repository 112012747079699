import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path stroke={color} fill={color} strokeWidth="0.2px" d="m3.71017,11.71607l0,10.38035l16.29633,0l0,-16.92721l-1.75616,-1.91674l-1.7511,-1.91674l-12.78907,0l0,10.38035zm12.14633,-7.82147l0,1.78639l3.34024,0l0,15.64295l-14.67682,0l0,-19.21572l11.33658,0l0,1.78639z" id="svg_1"/>
      <path stroke={color} fill={color} strokeWidth="0.2px" d="m7.06977,13.95205l0,4.51495l9.53114,0l-0.01009,-4.50066l-0.01513,-4.50066l-4.75548,-0.01429l-4.75044,-0.00953l0,4.51019zm2.86439,-2.76231l-0.01513,1.01444l-2.0676,0l-0.01513,-0.95252c-0.00504,-0.52389 0,-0.97633 0.01009,-1.00967c0.02017,-0.04763 0.26728,-0.06191 1.06406,-0.06191l1.0338,0l-0.01009,1.00967zm2.9249,0l-0.01513,1.01444l-2.0676,0l-0.01513,-0.95252c-0.00504,-0.52389 0,-0.97633 0.01009,-1.00967c0.02017,-0.04763 0.26728,-0.06191 1.06406,-0.06191l1.0338,0l-0.01009,1.00967zm2.9249,-0.95252c0.08573,0.08096 0.08573,1.85265 0,1.93362c-0.06556,0.06191 -1.87093,0.08573 -2.02726,0.02858c-0.0706,-0.02381 -0.08069,-0.15717 -0.08069,-0.99062c0,-0.53341 0.01513,-0.9811 0.0353,-0.99539c0.06052,-0.06191 2.00709,-0.0381 2.07265,0.02381zm-5.83972,3.70531l0,1.00015l-2.11803,0l0,-2.0003l2.11803,0l0,1.00015zm2.9249,0l0,1.00015l-2.11803,0l0,-2.0003l2.11803,0l0,1.00015zm2.95012,-0.89537c0.01513,0.06191 0.02017,0.50484 0.01513,0.99062l-0.01513,0.88108l-1.0691,0.01429l-1.07414,0.00953l0,-2.0003l1.05902,0c1.05397,0 1.05902,0 1.08423,0.10478zm-5.87502,3.65292c0,0.86203 -0.01009,1.00015 -0.08069,1.02396c-0.10086,0.0381 -1.8558,0.0381 -1.95666,0c-0.0706,-0.02381 -0.08069,-0.16193 -0.08069,-1.02396l0,-0.99539l2.11803,0l0,0.99539zm2.9249,0c0,0.86203 -0.01009,1.00015 -0.08069,1.02396c-0.10086,0.0381 -1.8558,0.0381 -1.95666,0c-0.0706,-0.02381 -0.08069,-0.16193 -0.08069,-1.02396l0,-0.99539l2.11803,0l0,0.99539zm2.94507,-0.91918c0.01513,0.04286 0.03026,0.48102 0.03026,0.97157c0,0.84298 -0.00504,0.9049 -0.09582,0.95252c-0.1059,0.05239 -1.8558,0.06668 -1.99196,0.01905c-0.0706,-0.02381 -0.08069,-0.16193 -0.08069,-1.02396l0,-0.99539l1.05397,0c0.91277,0 1.05902,0.00953 1.08423,0.0762z" id="svg_2"/>
    </>
  )
}

export const SpreadsheetIcon = createIcon(IconContent)
