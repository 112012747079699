import { defaultTheme } from './default-theme'
import { designPalette, fonts, unmappedColors } from './design-theme-properties'

export const darkTheme: typeof defaultTheme = {
  background: designPalette.primaryDarkMode,
  backgroundAlt: designPalette.secondaryDarkMode,
  primaryColor: designPalette.font.primaryDarkMode,
  secondaryColor: designPalette.font.primaryDarkMode,
  dangerColor: designPalette.accentRed,
  successColor: designPalette.accentGreen,
  dividerColor: unmappedColors.dividerColorDarkMode,
  body2Color: designPalette.font.secondaryDarkMode,
  toast: defaultTheme.toast,
  typography: {
    fontFamily: fonts.roboto,
    primaryColor: designPalette.font.primaryDarkMode,
    secondaryColor: designPalette.font.secondaryDarkMode,
    headers: defaultTheme.typography.headers,
    sizes: defaultTheme.typography.sizes,
  },
  gallery: {
    background: `${designPalette.primaryDarkMode}22`,
  },
  input: {
    color: designPalette.font.secondaryDarkMode,
    borderColor: unmappedColors.inputBorderColorDarkMode,
    label: {
      color: designPalette.font.secondaryDarkMode,
    },
  },
  select: {
    color: designPalette.font.primaryDarkMode,
  },
  backdropColor: 'rgba(0, 0, 0, 0.6)',
  boxShadowColor: 'rgba(0, 0, 0, 0.1)',
  isDark: true,
}
