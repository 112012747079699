import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 16.0845V19.0007H7.91619L16.517 10.3998L13.6008 7.48366L5 16.0845ZM18.773 8.14317C19.0763 7.83988 19.0763 7.34996 18.773 7.04668L16.9533 5.22697C16.65 4.92369 16.1601 4.92369 15.8568 5.22697L14.4337 6.65008L17.3499 9.56627L18.773 8.14317Z"
      fill={color}
    />
  )
}

export const EditIcon = createIcon(IconContent)
