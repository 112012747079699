/* eslint-disable multiline-ternary */
/* eslint-disable no-unneeded-ternary */
import {
  Tooltip,
  ThemeProvider,
  Typography,
  Box,
  TooltipProps,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { theme } from '../../avenue-8-platform-style'
import { SxProps } from '@mui/system'

type TooltipType = {
  title: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  icon?: JSX.Element
  arrow?: boolean
  description?: string
  titleStyles?: SxProps
  descriptionStyles?: SxProps
} & Partial<TooltipProps>

type TooltipDescriptionType = {
  title: string
  titleStyles?: SxProps
  description: string
  descriptionStyles?: SxProps
}

const TooltipDescription = ({
  title,
  titleStyles,
  description,
  descriptionStyles,
}: TooltipDescriptionType) => {
  return (
    <Box>
      <Box mb={description ? 2 : 0}>
        <Typography fontSize="11px" fontWeight={700} sx={titleStyles}>
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography fontSize="11px" fontWeight={400} sx={descriptionStyles}>
          {description}
        </Typography>
      )}
    </Box>
  )
}

export default function TooltipComponent({
  title,
  titleStyles,
  description,
  descriptionStyles,
  placement = 'top',
  arrow = true,
  icon,
}: TooltipType): JSX.Element {
  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={
          <TooltipDescription
            title={title}
            description={description}
            titleStyles={titleStyles}
            descriptionStyles={descriptionStyles}
          />
        }
        placement={placement}
        arrow={arrow}
        open={showTooltip}
        onOpen={() => setShowTooltip(true)}
        onClose={() => setShowTooltip(false)}
        id={title}
      >
        {icon ? (
          <IconButton
            onClick={() => setShowTooltip(!showTooltip)}
            style={{ opacity: 0.5, padding: '0 8px' }}
          >
            {icon}
          </IconButton>
        ) : (
          <IconButton
            color="secondary"
            style={{ opacity: 0.5, padding: '0 8px' }}
            onClick={() => setShowTooltip(!showTooltip)}
          >
            <InfoOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      </Tooltip>
    </ThemeProvider>
  )
}
