import styled from '@emotion/styled'
import MuiDatePicker from '@mui/lab/DatePicker'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { defaultTheme } from '../../theme'
import { CalendarIcon } from '../Icons'
import { useScreenSize } from '../../hooks'
import { IconButton, InputAdornment } from '@mui/material'

export type DatePickerProps = {
  title: string
  value: Date | null
  minDate?: Date
  maxDate?: Date
  disabled?: boolean
  setValueHandler: (value: Date) => void
  inputProps?: TextFieldProps
} & BoxProps

const DatePickerContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Title = styled(Typography)`
  font-family: Roboto, Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: ${defaultTheme.secondaryColor};
`

export const DatePicker = ({
  title,
  value,
  minDate,
  maxDate,
  disabled,
  setValueHandler,
  inputProps = {},
  ...boxProps
}: DatePickerProps): JSX.Element => {
  const { isDesktop } = useScreenSize()

  return (
    <DatePickerContainer {...boxProps}>
      <Title>{title}</Title>
      <MuiDatePicker
        value={value}
        components={{ OpenPickerIcon: CalendarIcon }}
        onChange={(newValue) => setValueHandler(newValue)}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            {...inputProps}
            {...(!isDesktop
              ? {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ marginRight: '-12px' }}>
                          <CalendarIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                  sx: {
                    padding: '0',
                  },
                }
              : {})}
          />
        )}
      />
    </DatePickerContainer>
  )
}
