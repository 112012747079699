import { breakpoints } from './breakpoints'

export const media = {
  desktopUp: `@media screen and (min-width: ${breakpoints.desktop + 1}px)`,
  desktopDown: `@media screen and (max-width: ${breakpoints.desktop}px)`,
  smallDesktopUp: `@media screen and (min-width: ${
    breakpoints.smallDesktop + 1
  }px)`,
  smallDesktopDown: `@media screen and (max-width: ${breakpoints.smallDesktop}px)`,
  tabletUp: `@media screen and (min-width: ${breakpoints.tablet + 1}px)`,
  tabletDown: `@media screen and (max-width: ${breakpoints.tablet}px)`,
  mobileUp: `@media screen and (min-width: ${breakpoints.mobile + 1}px)`,
  mobileDown: `@media screen and (max-width: ${breakpoints.mobile}px)`,
}
