import React from 'react'
import { Button } from '../Button'
import { ButtonTypeMap } from '@mui/material/Button'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import {
  ThemeProvider,
} from '@mui/material/styles'
import { theme } from '../../avenue-8-platform-style'

export interface ConfirmDialogProps
  extends Omit<DialogProps, 'onBackdropClick'> {
  onConfirm: () => void
  onCancel: () => void
  onClose?: () => void
  confirmLabel?: string
  cancelLabel?: string
  confirmVariant?: ButtonTypeMap['props']['variant']
  cancelVariant?: ButtonTypeMap['props']['variant']
  extraButtons?: React.ReactNode
  children?: React.ReactNode
  dialogTitle: React.ReactNode
}

export default function ConfirmDialog({
  onConfirm,
  onCancel,
  onClose,
  confirmLabel,
  cancelLabel,
  confirmVariant = 'outlined',
  cancelVariant = 'text',
  children,
  dialogTitle,
  extraButtons: _extraButtons,
  open,
  maxWidth,
}: ConfirmDialogProps): JSX.Element {
  return (
    <Dialog maxWidth={maxWidth} onClose={onClose} open={open}>
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid container flexDirection="column">
            {dialogTitle}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={theme}>
          {cancelLabel && (
            <Button onClick={onCancel} variant={cancelVariant}>
              {cancelLabel ?? 'Cancel'}
            </Button>
          )}
          <Button onClick={onConfirm} variant={confirmVariant}>
            {confirmLabel}
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  )
}
