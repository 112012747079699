import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.59 10.59L7 14.17L7 0L5 0L5 14.17L1.41 10.58L0 12L6 18L12 12L10.59 10.59ZM12 19H0V21H12V19Z"
        fill={color}
      />
    </svg>
  )
}

export const DownloadIcon = createIcon(IconContent)
