import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m9.23998,4.40385l0,2.02564l7.27533,0l-12.79533,11.73855l1.55664,1.4281l12.79539,-11.73857l0,6.67447l2.208,0l0,-10.12819l-11.04003,0z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </>
  )
}

export const LogOutIcon = createIcon(IconContent)
