import styled from '@emotion/styled'
import { Box, IconButton, Typography } from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

export type TemplateItemContainerType = {
  thumbnailUrl: string
  templateName: string
  onClick: () => void
  hasFavoriteButton?: boolean
  onFavoriteButtonClick?: () => void
  showTemplateName?: boolean
}

const ImageContainer = styled(Box)`
  width: 200px;
  height: 200px;
  border: 1px solid #d5d6ea;
  cursor: pointer;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
`

const FavoriteIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

export default function TemplateItemContainer({
  thumbnailUrl,
  templateName,
  onClick,
  hasFavoriteButton = false,
  onFavoriteButtonClick,
  showTemplateName = false,
}: TemplateItemContainerType): JSX.Element {
  return (
    <Box>
      <ImageContainer onClick={onClick}>
        <Image src={thumbnailUrl} alt={`${templateName} template thumbnail`} />
        {hasFavoriteButton
          ? (
          <FavoriteIconButton
            size="large"
            onClick={(e) => {
              e.stopPropagation()
              onFavoriteButtonClick?.()
            }}
          >
            <FavoriteBorderIcon />
          </FavoriteIconButton>
        )
          : null}
      </ImageContainer>

      {showTemplateName && (
        <Typography mt={1} variant="subtitle1" fontSize={14} maxWidth='200px'>
          {templateName}
        </Typography>
      )}
    </Box>
  )
}
