import { createIcon, IconOptions } from './createIcon'

function IconContent({
  color = '#73738C',
  outerColor = '#C4C4C4',
}: IconOptions & { outerColor?: string }) {
  return (
    <>
      <rect
        x="4.5"
        y="4.5"
        width="15"
        height="15"
        rx="7.5"
        stroke={outerColor}
      />
      <rect
        x="8.5"
        y="8.5"
        width="7"
        height="7"
        rx="3.5"
        fill={color}
        stroke={color}
      />
    </>
  )
}

export const RadioIcon = createIcon(IconContent)
