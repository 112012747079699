import { KeyboardArrowDown } from '@mui/icons-material'
import { createTheme, Input, ThemeOptions } from '@mui/material'
import * as React from 'react'
import { darkTheme } from './dark-theme'
import { defaultTheme } from './default-theme'
import { fonts } from './design-theme-properties'

export type PlatformStyleTheme = typeof defaultTheme

declare module '@mui/material/styles' {
  export interface Theme {
    platformStyle: PlatformStyleTheme
  }
  // allow configuration using `createTheme`
  export interface ThemeOptions {
    platformStyle: PlatformStyleTheme
  }
}

declare module '@emotion/react' {
  export interface Theme {
    platformStyle: PlatformStyleTheme
  }
}

function generateMuiThemeOptions(t: PlatformStyleTheme): ThemeOptions {
  const buttonHoverEffect = {
    '&:active': {
      background: t.backgroundAlt,
    },
    '&:focus': {
      background: t.backgroundAlt,
    },
    '&:hover': {
      background: `${t.backgroundAlt}!important`,
    },
  }

  const options: ThemeOptions = {
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontWeight: 300,
          },
          paragraph: {
            color: t.secondaryColor,
          },
          h1: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 400,
            fontSize: 48,
          },
          h2: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 400,
            fontSize: 40,
          },
          h3: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 400,
            fontSize: 32,
          },
          h4: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 400,
            fontSize: 28,
          },
          h5: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 500,
            fontSize: 24,
          },
          h6: {
            color: t.primaryColor,
            fontFamily: fonts.canela,
            fontWeight: 600,
            fontSize: 20,
          },
          body2: {
            color: t.body2Color,
            fontFamily: fonts.roboto,
            fontWeight: 400,
            fontSize: 14,
          },
          button: {
            fontFamily: t.typography.fontFamily,
            fontWeight: 500,
            textTransform: 'uppercase',
            fontSize: '12px',
            letterSpacing: '2px',
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            fontFamily: t.typography.fontFamily,
            fontWeight: 500,
            textTransform: 'uppercase',
            fontSize: '12px',
            letterSpacing: '2px',
            borderRadius: '4px !important',
          },
          text: {
            padding: '12px 18px',
            '&:hover': {
              background: 'transparent',
            },
          },
          outlined: {
            padding: '12px 18px',
            borderColor: t.primaryColor,
            minWidth: '100px',
            ...buttonHoverEffect,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              fontWeight: 700,
              borderBottom: '2px solid black',
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiCardMedia: {
        styleOverrides: {
          root: {
            borderRadius: 4,
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          shrink: false,
        },
        styleOverrides: {
          root: {
            fontFamily: t.typography.fontFamily,
            color: t.input.label.color,
            textTransform: 'uppercase',
            marginBottom: '2px',
            letterSpacing: '2px',
            fontSize: '10px',
            position: 'static' as any,
            transform: 'none' as any,
            '&$error': {
              color: t.input.label.color,
            },
          },
        },
      },
      MuiList: {
        defaultProps: {
          disablePadding: true,
        },
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiMenu: {
        defaultProps: {
          elevation: 2,
        },
        styleOverrides: {
          root: {
            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent',
            },
          },
          list: {
            padding: 0,
            borderRadius: '4px',
            boxShadow: '0px 4px 30px 0px #0000001A',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '14px 18px 12px',
            minWidth: '100%',
            '&$selected': {
              background: t.backgroundAlt, // Selected effect for a multiselect (needs to define a color)
            },
            '&:focus': {
              background: t.backgroundAlt,
            },
            '&:hover': {
              background: t.backgroundAlt,
            },
          },
          divider: {
            borderBottom: `1px solid ${t.dividerColor}`,
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            '& > span': {
              background: t.background,
            },
            '&:hover': {
              '& > span': {
                opacity: '0.2',
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            fontFamily: t.typography.fontFamily,
            color: t.primaryColor,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          input: React.createElement(Input),
          IconComponent: KeyboardArrowDown,
        },
        styleOverrides: {
          root: {
            color: t.primaryColor,
            '&:focus': {
              backgroundColor: `${t.backgroundAlt}!important`,
            },
          },
          icon: {
            width: '2.4rem',
            color: t.primaryColor,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            borderBottom: `solid 1px ${t.dividerColor}`,
            padding: '16px 26px',
            '& svg': {
              color: t.primaryColor,
            },
            '& span': {
              color: t.primaryColor,
            },
            '&$selected': {
              backgroundColor: t.backgroundAlt,
            },
          },
          button: {
            ...buttonHoverEffect,
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            '& svg': {
              borderBottom: `3px solid transparent`,
            },
            '&.Mui-selected': {
              '& svg': {
                color: t.primaryColor,
                borderBottom: `3px solid ${t.typography.primaryColor}`,
                borderRadius: '0px 0px 2px 2px',
                paddingTop: '10px',
              },
            },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: '70px',
            backgroundColor: t.backgroundAlt,
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: t.background,
            color: t.primaryColor,
            boxShadow: `1px 2px 6px ${t.boxShadowColor}`,
            padding: '12px 16px',
            borderRadius: 5,
            maxWidth: 250,
          },
          arrow: {
            color: t.background,
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(250, 250, 255, 0.8)',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& [class^="MuiBackdrop-root"]': {
              backgroundColor: ' t.backdropColor',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '24px 24px 16px 24px',
            fontFamily: t.typography.headers.fontFamily,
            '& h2': {
              fontFamily: 'inherit',
            },
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            boxSizing: 'border-box',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '0 24px 24px 24px',
            '& button:not(:first-child)': {
              marginLeft: '24px',
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            padding: '32px 0 24px 0',
            boxShadow: 'none',
            backgroundColor: t.background,
            flexGrow: 1,
          },
        },
      },
      MuiOutlinedInput: {
        defaultProps: {
          notched: false,
        },
        styleOverrides: {
          input: {
            padding: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: t.input.color,
            borderRadius: '4px',
            backgroundColor: t.background,
            border: `1px solid ${t.input.borderColor}`,
            width: '100%',
            margin: 0,
            padding: '7px 16px',
            '&.Mui-disabled': {
              opacity: '0.5',
            },
            '&.Mui-focused': {
              backgroundColor: t.backgroundAlt,
              border: `2px solid ${t.input.borderColor}`,
              padding: '6px 15px',
            },
            '&.Mui-error': {
              borderColor: t.dangerColor,
            },
          },
          input: {
            '&::placeholder': {
              opacity: 0.4,
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& [class^=MuiInputBase-root]': {
              marginTop: 0,
            },
            width: '100%',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '& span': {
              color: t.input.label.color,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 10,
            textTransform: 'uppercase',
            letterSpacing: '2px',
            '&$error': {
              color: t.dangerColor,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&::after': {
              display: 'none',
            },
            '&::before': {
              display: 'none',
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          InputProps: {
            notched: false,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: 0,
            borderBottom: 0,
            backgroundColor: t.background,
            '&.Mui-expanded': {
              margin: 0,
            },
            '&::before': {
              display: 'none',
            },
          },
          expanded: {},
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '1rem',
            flexDirection: 'column',
            gap: '24px',
            background: t.backgroundAlt,
          },
        },
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: '8px 0',
            minHeight: 'initial',
            backgroundColor: t.background,
            '&.Mui-expanded': {
              minHeight: 'initial',
              background: t.background,
            },
          },
          content: {
            minHeight: 'initial',
            margin: 0,
            padding: 0,
            '&.Mui-expanded': {
              margin: 0,
            },
          },
          expanded: {},
          expandIconWrapper: {
            marginRight: '8px',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 429,
        md: 641,
        lg: 1007,
        xl: 1280,
      },
    },
    shape: {
      borderRadius: 4,
    },
    typography: {
      body1: {
        color: t.primaryColor,
      },
      body2: {
        color: t.secondaryColor,
      },
      fontFamily: t.typography.fontFamily,
      h1: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
      h2: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
      h3: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
      h4: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
      h5: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
      h6: {
        fontFamily: t.typography.headers.fontFamily,
        color: t.primaryColor,
      },
    },
    shadows: [
      'none',
      ...Array.from(
        { length: 24 },
        () => `0px 0px 40px 0px rgba(0,0,0, ${t.isDark ? '0.7' : 0.1})`,
      ),
    ] as any,
    palette: {
      mode: t.isDark ? 'dark' : 'light',
      primary: {
        main: t.primaryColor,
      },
      secondary: {
        main: t.secondaryColor,
      },
      error: { main: t.dangerColor },
      success: { main: t.successColor },
      text: {
        primary: t.primaryColor,
        secondary: t.secondaryColor,
        disabled: '#C4C4C4', //from a design - need review
      },
      warning: {
        main: t.dangerColor,
      },
      divider: '#C4C4C4',
      background: {
        default: t.background,
        paper: t.background,
      },
    },
    platformStyle: defaultTheme,
  }
  return options
}

export const muiDefaultTheme = createTheme(
  generateMuiThemeOptions(defaultTheme),
)
export const muiDarkTheme = createTheme(generateMuiThemeOptions(darkTheme))
