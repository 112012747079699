import { ThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import { TextField, TextFieldProps } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'
import { InputBaseProps } from '../Input'

const DatepickerInput = styled(TextField)`
  & input::-webkit-inner-spin-button,
  input::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }
  & input:focus {
    &::-webkit-calendar-picker-indicator {
      right: 7px;
    }
  }
  ${(_p) => {
    const color = theme.palette.primary.main.replace('#', '%23')
    return `
    & input::-webkit-calendar-picker-indicator {
      cursor: pointer;
      background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8"?><svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect x="4.5" y="4.5" width="15" height="15" rx="1.5" stroke="${color}"/><rect transform="rotate(-90 5 7)" x="5" y="7" width="2" height="14" fill="${color}"/><rect x="7" y="9" width="2" height="2" fill="${color}"/><rect x="11" y="9" width="2" height="2" fill="${color}"/><rect x="15" y="9" width="2" height="2" fill="${color}"/><rect x="7" y="13" width="2" height="2" fill="${color}"/><rect x="11" y="13" width="2" height="2" fill="${color}"/><rect x="15" y="13" width="2" height="2" fill="${color}"/></svg>');
      width: 24px;
      height: 24px;
      padding: 0;
      position: absolute;
      right: 8px;
      cursor: pointer;
    }`
  }}
`

export default function Datepicker(props: TextFieldProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <DatepickerInput
        {...props}
        type="date"
        fullWidth
        variant="standard"
      />
    </ThemeProvider>
  )
}
