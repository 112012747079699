import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#131319', size }: IconOptions) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 2H8.58711" stroke={color} />
      <path d="M0 14H5.15227" stroke={color} />
      <path d="M15.457 8L6.86992 8" stroke={color} />
      <path d="M10.3047 2H15.457" stroke={color} />
      <path d="M7.72852 14L15.4569 14" stroke={color} />
      <path d="M5.15234 8L7.77245e-05 8" stroke={color} />
      <path d="M10.3047 4L10.3047 -5.96046e-08" stroke={color} />
      <path d="M7.72852 16L7.72852 12" stroke={color} />
      <path d="M5.15234 6L5.15234 10" stroke={color} />
    </svg>
  )
}

export const FilterIcon = createIcon(IconContent)
