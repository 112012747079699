/* eslint-disable multiline-ternary */
import React from 'react'
import {
  Backdrop,
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  styled,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {
  CheckboxIcon,
  PagePreviews,
  ShareOutlineIcon,
  theme,
} from '../../avenue-8-platform-style'
import { ModalTitle } from '../Modal'
import FacebookOutlined from '@mui/icons-material/FacebookOutlined'
import Instagram from '@mui/icons-material/Instagram'
import DownloadOutlined from '@mui/icons-material/DownloadOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { ContentCopy } from '@mui/icons-material'

export type ProjectImage = {
  id: string
  url: string
}

export type ExportFileType = 'pdf' | 'png' | 'mp4'

export type SocialShareOption = 'facebook' | 'instagram'

export type DefaultFile = {
  label: string
  value: ExportFileType
  disabled?: boolean
}

export type Handlers =
  | 'preview'
  | 'emailShare'
  | 'socialShare'
  | 'download'
  | 'caption'
  | 'customize'
  | 'generateCaptionWithAI'

export type OneFieldHandlersFn = Partial<
  Record<'onChange' | 'onClick', (...args: any) => any>
>

export type MultipleFieldsHandlersFn = Partial<
  Record<'fields', Record<string, OneFieldHandlersFn>>
>

export type Triggers = Partial<
  Record<Handlers, boolean | OneFieldHandlersFn | MultipleFieldsHandlersFn>
>

const RowButton = ({ sx, variant, ...props }: ButtonProps) => (
  <Button
    sx={{ paddingY: 1, marginTop: 2, ...sx }}
    variant="outlined"
    {...props}
  />
)

enum ContentContextEnum {
  'LISTING_LOCATION' = 'LISTING_LOCATION',
  'LISTING_INFORMATION_FROM_PROPERTY_SEARCH' = 'LISTING_INFORMATION_FROM_PROPERTY_SEARCH',
}

interface PropertyInfoDto {
  mlsId?: string | null
  mlsSource?: string | null
  addressLine1?: string
  addressLine2?: string | null
  area?: string | null
  numBathrooms?: number | null
  numBedrooms?: number | null
  lat?: number | null
  lng?: number | null
  listingStatus?: string | null
  city?: string
  description?: string | null
  parkingGarageSpaces?: number | null
  listDate?: Date | null
  listPrice?: number | null
  livingSpace?: number | null
  lotSize?: number | null
  neighborhood?: string | null
  propertyType?: string | null
  state?: string
  yearBuilt?: number | null
  zipCode?: string
  soldPrice?: number | null
  soldDate?: Date | null
  county?: string | null
}

export type ExportShareModalProps = {
  open: boolean
  onClose: () => void
  status: 'IDLE' | 'LOADING' | 'ERROR'
  captionStatus?: 'IDLE' | 'LOADING' | 'ERROR'
  title: string
  subtitle?: string
  previews: ProjectImage[]
  triggers: Triggers
  values?: {
    fileName?: string
    fileType?: string
    caption?: string
    targetAudience?: string
    targetAudienceTopic?: string
    captionContext?: string
    tonality?: string
    hashtagsOption?: 'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'
  }
  exportFileTypes?: DefaultFile[]
  socialOptions?: SocialShareOption[]
  socialMediaShareMessage?: string
  promoteCTA?: JSX.Element
  targetAudiencesOptions?: Array<{
    label: string
    value: string
    options: Array<string>
  }>
  tonalitiesOptions?: Array<{
    label: string
    value: string
  }>
  onGenerateCaptionClick?: (params: {
    captionContext?: string
    address?: string
    contentContexts?: ContentContextEnum[]
    maxNumberOfWords?: number
    propertyInfo?: PropertyInfoDto
    numberOfOutputs?: number
    tonality?: string
    contentType?: 'CAPTION' | 'SOCIAL_MEDIA_POST'
    targetAudience?: string
    targetAudienceTopic?: string
    hashtagsOption?: 'INCLUDE' | 'ONLY' | 'DO_NOT_INCLUDE'
  }) => void
}

const ModalContent = styled(Box)`
  box-shadow: 1px 4px 40px rgba(0, 0, 0, 0.25);
  background: white;
  outline: none;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: min(16em, 100vh);
  min-width: 20em;
  border: none;

  @media (max-width: 641px) {
    border-radius: 14px 14px 0 0;
    width: 100%;
    height: calc(100vh - 24px);
    overflow-y: scroll;
  }

  @media (min-width: 641px) {
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
`

export default function ExportShareModal({
  open,
  onClose,
  status,
  title,
  subtitle,
  previews,
  triggers,
  values,
  exportFileTypes = [{ label: 'PNG', value: 'png', disabled: false }],
  targetAudiencesOptions,
  tonalitiesOptions,
  socialOptions,
  socialMediaShareMessage,
  promoteCTA,
  captionStatus,
}: ExportShareModalProps): JSX.Element {
  const [openTooltip, setOpenTooltip] = React.useState(false)
  const isGeneratingCaption = captionStatus === 'LOADING'

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
    setTimeout(() => {
      setOpenTooltip(false)
    }, 5000)
  }
  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const generateCaptionWithAIHandlers =
    triggers.generateCaptionWithAI as MultipleFieldsHandlersFn

  const handleGenerateCaption = () => {
    if (!triggers.generateCaptionWithAI) return
    const trigger = triggers.generateCaptionWithAI as OneFieldHandlersFn
    trigger.onClick()
  }

  const targetAudienceTopicOptions =
    targetAudiencesOptions.find((tA) => tA.value === values.targetAudience)
      ?.options || []

  const isHandleGenerateCaptionDisabled =
    (!values.targetAudience && !values.targetAudienceTopic) ||
    !values.tonality ||
    isGeneratingCaption

  return (
    <Modal open={open} BackdropComponent={StyledBackdrop}>
      <ModalContent
        sx={{
          flexDirection: 'column',
          padding: '1rem 1rem 2rem 1rem',
          minWidth: '75vw',
          maxWidth: '100vw',
          minHeight: '60vh',
          maxHeight: '100vh',
          overflowY: 'auto',
          boxSizing: 'border-box',
          height: 'initial!important',
        }}
      >
        <ThemeProvider theme={theme}>
          {status !== 'IDLE' ? (
            <>
              <ModalTitle onClose={onClose} sx={{ paddingLeft: 2 }}>
                {status === 'ERROR' ? 'An error occurred' : 'Loading...'}
              </ModalTitle>
              <Grid container justifyContent="center" alignItems="center">
                {status === 'LOADING' && <CircularProgress />}
                {status === 'ERROR' && (
                  <Typography>
                    Oops, something went wrong. Please try again later.
                  </Typography>
                )}
              </Grid>
            </>
          ) : (
            <>
              <ModalTitle
                onClose={onClose}
                paddingLeft={triggers.preview ? 4 : 1}
              >
                <Typography variant="h4" component="h1">
                  {title}
                </Typography>
                {subtitle && (
                  <Typography variant="subtitle1">{subtitle}</Typography>
                )}
              </ModalTitle>
              <Grid
                container
                flexDirection="row"
                flexWrap="nowrap"
                spacing={triggers.preview ? 2 : 0}
                paddingX={triggers.preview ? 3 : 1}
                paddingBottom={4}
              >
                {triggers.preview && <PagePreviews item pages={previews} />}

                <Grid item container rowSpacing={3} mt={1}>
                  <Grid
                    item
                    container
                    flexDirection="column"
                    rowSpacing={3}
                    flex="1 1 0px"
                  >
                    {triggers.download && (
                      <>
                        <Grid
                          item
                          container
                          flexDirection="row"
                          rowSpacing={1}
                          alignItems="center"
                        >
                          <Typography variant="button">DOWNLOAD</Typography>
                        </Grid>
                        <Grid
                          item
                          container
                          flexDirection="row"
                          spacing={1}
                          alignItems="flex-end"
                          flexWrap={'wrap'}
                        >
                          <Grid item container sm={6} md={5}>
                            <TextField
                              label="Filename"
                              placeholder="Filename"
                              value={values?.fileName ?? ''}
                              onChange={
                                (triggers.download as MultipleFieldsHandlersFn)
                                  ?.fields?.fileName?.onChange
                              }
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                style: { border: 'none' },
                                notched: false,
                              }}
                              sx={{
                                paddingLeft: 0,
                                marginLeft: 0,
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            md={5}
                            sx={{ justifyContent: 'space-around' }}
                            container
                            flexDirection={'row'}
                          >
                            <Select
                              label=" "
                              variant="outlined"
                              input={<OutlinedInput />}
                              defaultValue={values?.fileType ?? ''}
                              onChange={
                                (triggers.download as MultipleFieldsHandlersFn)
                                  ?.fields?.fileType?.onChange
                              }
                              MenuProps={{ hideBackdrop: true }}
                              disabled={exportFileTypes.length <= 1}
                              sx={{ flex: 1, border: 0 }}
                            >
                              {exportFileTypes.map(({ label, value }, idx) => (
                                <MenuItem
                                  key={`filetype-${idx}-${value}`}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                            <Button
                              color="secondary"
                              onClick={
                                (triggers.download as OneFieldHandlersFn)
                                  ?.onClick
                              }
                              sx={{
                                padding: 0,
                                minWidth: '36px',
                                maxHeight: '100%',
                                height: '36px',
                                marginLeft: 1,
                              }}
                            >
                              <DownloadOutlined color="secondary" />
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* CAPTION GENERATOR */}
                  {!!generateCaptionWithAIHandlers && (
                    <Grid item container rowSpacing={2} mb={1}>
                      <Grid item container alignItems="center" md={12}>
                        <Typography
                          variant="button"
                          style={{ display: 'flex', alignItems: 'end' }}
                        >
                          Caption Generator{' '}
                          <Tooltip title="Leverage AI to craft your captions effortlessly.">
                            <IconButton>
                              <AutoAwesomeIcon
                                style={{ fontSize: '16px' }}
                                color={'primary'}
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Grid>

                      <Grid item container sm={12} md={10}>
                        <TextField
                          id="target-audience"
                          select
                          label="Target Audience"
                          value={values.targetAudience}
                          onChange={
                            generateCaptionWithAIHandlers.fields?.targetAudience
                              .onChange
                          }
                          disabled={isGeneratingCaption}
                        >
                          {targetAudiencesOptions.map(({ label, value }) => (
                            <MenuItem
                              key={`target-audience-${value}`}
                              value={value}
                            >
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {!!values.targetAudience &&
                        targetAudienceTopicOptions.length > 0 && (
                          <Grid item container sm={12} md={10}>
                            <TextField
                              id="target-audience-topic"
                              label="Topic (Optional)"
                              select
                              value={values.targetAudienceTopic}
                              onChange={
                                generateCaptionWithAIHandlers.fields
                                  ?.targetAudienceTopic.onChange
                              }
                              fullWidth
                              disabled={isGeneratingCaption}
                            >
                              <MenuItem
                                key={`target-audience-topic-empty`}
                                value={''}
                              >
                                <em>None</em>
                              </MenuItem>
                              {targetAudienceTopicOptions.map((option) => (
                                <MenuItem
                                  key={`target-audience-topic-${option}`}
                                  value={option}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        )}

                      <Grid item container sm={12} md={10}>
                        <TextField
                          id="tonality"
                          select
                          label="Tonality"
                          value={values.tonality}
                          onChange={
                            generateCaptionWithAIHandlers.fields?.tonality
                              .onChange
                          }
                          fullWidth
                          disabled={isGeneratingCaption}
                        >
                          {tonalitiesOptions.map(({ label, value }) => (
                            <MenuItem key={`tonality-${value}`} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item container sm={12} md={10}>
                        <TextField
                          id="caption-context"
                          label={<>Caption Context (Optional)</>}
                          value={values.captionContext}
                          onChange={
                            generateCaptionWithAIHandlers.fields?.captionContext
                              .onChange
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            style: { border: 'none' },
                            notched: false,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  marginLeft: '-0.5rem',
                                  cursor: 'default',
                                }}
                              >
                                <Tooltip title="Add personalized text to create custom captions.">
                                  <IconButton>
                                    <InfoOutlined />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ paddingLeft: 0, marginLeft: 0 }}
                          helperText="Max 50 characters"
                          FormHelperTextProps={{
                            style: { margin: '0.2rem 0 0 0', padding: 0 },
                          }}
                          fullWidth
                          disabled={isGeneratingCaption}
                        />
                      </Grid>

                      <Grid item container sm={12} md={10}>
                        <Tooltip
                          title="Add relevant hashtags to your caption for increased online visibility and traffic."
                          placement="right"
                        >
                          <div>
                            <FormControlLabel
                              disabled={isGeneratingCaption}
                              style={{ maxHeight: '32px' }}
                              control={
                                <Checkbox
                                  checked={values.hashtagsOption === 'INCLUDE'}
                                  disabled={isGeneratingCaption}
                                  size="small"
                                  onChange={
                                    generateCaptionWithAIHandlers.fields
                                      ?.hashtagsOption.onChange
                                  }
                                  checkedIcon={
                                    <CheckboxIcon
                                      color={theme.palette.primary.main}
                                      backgroundColor="transparent"
                                    />
                                  }
                                  icon={
                                    <CheckboxIcon
                                      color="transparent"
                                      backgroundColor="transparent"
                                    />
                                  }
                                />
                              }
                              label={
                                <Typography color="#73738c" variant="body2">
                                  Add Hashtags
                                </Typography>
                              }
                            />
                          </div>
                        </Tooltip>
                      </Grid>

                      <Grid item container sm={12}>
                        <Button
                          sx={{ paddingY: 1 }}
                          variant="outlined"
                          onClick={handleGenerateCaption}
                          disabled={isHandleGenerateCaptionDisabled}
                          type="button"
                          startIcon={
                            isGeneratingCaption && (
                              <CircularProgress
                                size={16}
                                style={{ marginRight: '0.5rem' }}
                              />
                            )
                          }
                        >
                          {!isGeneratingCaption
                            ? 'Generate Caption'
                            : 'Generating...'}
                        </Button>
                      </Grid>

                      <Grid
                        item
                        container
                        sm={12}
                        md={10}
                        alignItems="flex-end"
                        flexDirection="row"
                        mt={1}
                      >
                        <TextField
                          label="caption"
                          placeholder="Caption"
                          value={values.caption}
                          onChange={
                            (triggers.caption as OneFieldHandlersFn)?.onChange
                          }
                          multiline
                          rows={6}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            style: { border: 'none' },
                            notched: false,
                          }}
                          sx={{ flex: 1, paddingLeft: 0, marginLeft: 0 }}
                        />
                        <Button
                          color="secondary"
                          onClick={
                            (triggers.caption as OneFieldHandlersFn)?.onClick
                          }
                          sx={{
                            padding: 0,
                            minWidth: '36px',
                            maxHeight: '100%',
                            height: '36px',
                            marginBottom: '2px',
                            marginLeft: 1,
                          }}
                        >
                          <ClickAwayListener onClickAway={handleTooltipClose}>
                            <Tooltip
                              onClose={handleTooltipClose}
                              open={openTooltip}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Copied!"
                            >
                              <ContentCopy
                                onClick={handleTooltipOpen}
                                color="secondary"
                              />
                            </Tooltip>
                          </ClickAwayListener>
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  <Grid item container rowSpacing={3}>
                    {(triggers.emailShare ||
                      triggers.socialShare ||
                      socialMediaShareMessage) && (
                      <Grid
                        item
                        container
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Typography variant="button">SHARE</Typography>
                      </Grid>
                    )}

                    {triggers.emailShare && (
                      <Grid
                        item
                        container
                        flexDirection="row"
                        spacing={2}
                        alignItems="center"
                      >
                        <Grid item flexGrow={1}>
                          <TextField
                            label="EMAIL"
                            placeholder="youremail@avenue8.com"
                            onChange={
                              (triggers.emailShare as OneFieldHandlersFn)
                                ?.onChange
                            }
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              style: { border: 'none' },
                              notched: false,
                            }}
                          />
                        </Grid>
                        <Grid item flexShrink={1} pr={2}>
                          <RowButton
                            onClick={
                              (triggers.emailShare as OneFieldHandlersFn)
                                ?.onClick
                            }
                          >
                            {<ShareOutlineIcon />}
                          </RowButton>
                        </Grid>
                      </Grid>
                    )}

                    {triggers.socialShare ? (
                      <Grid
                        item
                        container
                        flexDirection="row"
                        spacing={2}
                        alignItems="center"
                      >
                        {(!socialOptions ||
                          socialOptions.includes('facebook')) && (
                          <Grid item flexGrow={1}>
                            <Button
                              variant="text"
                              fullWidth
                              endIcon={<FacebookOutlined />}
                              onClick={(
                                triggers.socialShare as OneFieldHandlersFn
                              )?.onClick('facebook')}
                            >
                              Post to Facebook
                            </Button>
                          </Grid>
                        )}
                        {(!socialOptions ||
                          socialOptions.includes('instagram')) && (
                          <Grid item flexGrow={1}>
                            <Button
                              variant="text"
                              fullWidth
                              endIcon={<Instagram />}
                              onClick={(
                                triggers.socialShare as OneFieldHandlersFn
                              )?.onClick('instagram')}
                            >
                              Post to Instagram
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    ) : socialMediaShareMessage ? (
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        marginTop={0.5}
                        paddingX={2}
                      >
                        <InfoOutlined
                          color="secondary"
                          width={2}
                          style={{ marginRight: '16px' }}
                        />
                        <Typography
                          color="secondary"
                          fontSize="80%"
                          align="center"
                        >
                          {socialMediaShareMessage}
                        </Typography>
                      </Grid>
                    ) : null}

                    {triggers.customize && (
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        marginTop={0.5}
                      >
                        <RowButton
                          onClick={
                            (triggers.customize as OneFieldHandlersFn)?.onClick
                          }
                        >
                          Customize
                        </RowButton>
                      </Grid>
                    )}
                  </Grid>

                  {promoteCTA && (
                    <Grid container flexDirection="column">
                      <Grid
                        item
                        flexGrow={1}
                        container
                        flexDirection="column"
                        justifyContent="end"
                      >
                        {promoteCTA}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </ThemeProvider>
      </ModalContent>
    </Modal>
  )
}
