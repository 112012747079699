import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#131319' }: IconOptions) {
  return (
    <g>
      <rect
        stroke={color}
        rx="1.5"
        height="15"
        width="14.8944"
        y="4.71686"
        x="4.47599"
      />
      <rect
        transform="rotate(-90 4.96915 7.21686)"
        fill={color}
        height="13.9076"
        width="2"
        y="7.21686"
        x="4.96915"
      />
      <rect fill={color} height="2" width="1.9868" y="9.21686" x="6.95646" />
      <rect fill={color} height="2" width="1.9868" y="9.21686" x="10.93009" />
      <rect fill={color} height="2" width="1.9868" y="9.21686" x="14.90375" />
      <rect fill={color} height="2" width="1.9868" y="13.21686" x="6.95646" />
      <rect fill={color} height="2" width="1.9868" y="13.21686" x="10.93009" />
      <rect fill={color} height="2" width="1.9868" y="13.21686" x="14.90375" />
    </g>
  )
}

export const CalendarIcon = createIcon(IconContent)
