import { SpreadsheetIcon } from '../Icons/SpreadsheetIcon'
import { muiDefaultTheme as theme } from '../../theme/material-themes'
import styled from '@emotion/styled'
import { Button, Typography, Box } from '@mui/material'
import { useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import UploadingFile from './UploadProgressBar'

const Wrapper = styled(Box)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 188px;
`

const CsvUploaderBox = styled(Box)<{ disabled?: boolean }>`
  ${(p) => (p.disabled === true ? 'pointer-events: none;' : 'cursor: pointer;')}
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  color: ${(p) => (p.disabled === true ? 'rgba(0,0,0,.2)' : '#D5D6EA')};
  border: 1px ${(p) => (p.disabled === true ? 'rgba(0,0,0,.2)' : '#D5D6EA')}
    dashed;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
  outline: none !important;
  p {
    margin: 0.25rem 0;
  }
  input {
    border-radius: 1rem;
    outline: none !important;
  }
`

const DragAndDropText = styled(Typography)`
  font-size: 14px;
`

const BrowseFileButton = styled(Button)`
  border-color: #d5d6ea;
  padding-left: 19px;
  &:hover {
    border-color: #d5d6ea;
  }
`

const DownloadButton = styled(Button)`
  color: ${(p) => theme.palette.secondary.main};
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.8px;
  text-decoration: underline;
  padding-bottom: 0;
  &:hover {
    text-decoration: underline;
  }
`

const ErrorText = styled(Typography)`
  color: ${(p) => theme.palette.error.main};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  left: 0;
  margin-top: 16px !important;
`

const ErrorMessage = ({ message }: { message?: string }) => {
  return <ErrorText variant="body2">{message ?? ''}</ErrorText>
}

export type CSVUploaderProps = {
  exampleTemplateURL: string
  onFileUploaded: (files: File[]) => void
  onFileDeleted: (file: File) => void
  onUploadError: (error: any) => void
}

const acceptedFileTypes = [
  '.csv',
  'text/csv',
  'application/vnd.ms-excel',
  'application/csv',
  'text/x-csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
]

export default function CsvUploader({
  exampleTemplateURL,
  onFileUploaded,
  onFileDeleted,
}: CSVUploaderProps): JSX.Element {
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([])
  const [errorMessage, setErrorMessage] = useState(null)
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    maxFiles: 1,
    accept: acceptedFileTypes.join(','),
    onDropAccepted: (acceptedFiles: File[]) => {
      setErrorMessage(null)
      setUploadingFiles([...acceptedFiles])
      onFileUploaded(acceptedFiles)
    },
    onDropRejected: (rejectedFiles: FileRejection[]) => {
      const rejectedFileType = rejectedFiles[0].file.type
      setErrorMessage(`${rejectedFileType} file is not a csv file.`)
    },
  })

  const deleteUploadingFile = (file: File) => {
    const filteredUploadingFiles = uploadingFiles.filter(
      (uploadingFile) =>
        uploadingFile.name !== file.name &&
        uploadingFile.lastModified !== file.lastModified,
    )

    setUploadingFiles([...filteredUploadingFiles])
    onFileDeleted(file)
  }

  return (
    <Wrapper color="grey">
      <CsvUploaderBox {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} id="csv-uploader-input" />
        <SpreadsheetIcon color="#73738C" />
        <DragAndDropText color="secondary">
          Drag + drop or upload a CSV here <br /> of your custom list.
        </DragAndDropText>

        <BrowseFileButton disableRipple size="small" onClick={open}>
          Browse Files
        </BrowseFileButton>

        {!!errorMessage && <ErrorMessage message={errorMessage} />}

        <DownloadButton variant="text" disableRipple href={exampleTemplateURL}>
          Download our CSV template here.
        </DownloadButton>
      </CsvUploaderBox>

      {uploadingFiles.map((file) => (
        <UploadingFile
          key={`${file.name}-${file.lastModified}`}
          name={file.name}
          onDelete={() => deleteUploadingFile(file)}
        />
      ))}
    </Wrapper>
  )
}
