import styled from '@emotion/styled'
import {
  Avatar,
  AvatarTypeMap,
  Box,
  Menu,
  MenuItem,
  ThemeProvider,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { MouseEvent, MouseEventHandler, useState } from 'react'
import {
  PlatformIcon,
  PlatformIconNames,
  theme,
} from '../../avenue-8-platform-style'

const AgentAvatar = styled(Avatar)`
  .MuiAvatar-img {
    position: absolute;
  }

  .MuiAvatar-fallback {
    position: absolute;
    width: 50%;
    height: 50%;
  }
` as OverridableComponent<AvatarTypeMap>

export type OptionDto = {
  label: string
  value: string
  id?: number | string
  order?: number
  icon?: PlatformIconNames
}

export type UserAvatarDropdownProps = {
  userName?: string
  userAvatarUrl?: string
  options: OptionDto[]
  onClick?: (
    setAnchorEl: (value: null | HTMLElement) => void,
    currentTarget: HTMLElement,
  ) => void
  onClose?: (setAnchorEl: (value: null | HTMLElement) => void) => void
  onMenuItemClick?: (
    option: OptionDto,
    event: MouseEvent<HTMLAnchorElement>,
  ) => void
}

export const UserAvatarDropDown = (
  props: UserAvatarDropdownProps,
): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (props.onClick) {
      props.onClick(setAnchorEl, event.currentTarget)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const handleClose = () => {
    if (props.onClose) {
      props.onClose(setAnchorEl)
    } else {
      setAnchorEl(null)
    }
  }
  const generateMenuItemClickHandler = (
    option: OptionDto,
  ): MouseEventHandler<HTMLAnchorElement> => {
    return (event) => {
      handleClose()
      props.onMenuItemClick(option, event)
    }
  }
  const { userName, userAvatarUrl, options } = props
  const menuItems = options.map((option, idx) => {
    const handler = generateMenuItemClickHandler(option)
    return (
      <MenuItem
        key={idx}
        component={'a'}
        href={option.value}
        onClick={handler}
        divider={idx !== options.length - 1}
      >
        {!!option.icon && (
          <Box
            mr="10px"
            display="flex"
            alignItems="center"
            position="relative"
            left="-3px"
          >
            <PlatformIcon name={option.icon} size={20} />
          </Box>
        )}
        {option.label}
      </MenuItem>
    )
  })
  return (
    <ThemeProvider theme={theme}>
      <AgentAvatar
        component="button"
        alt={userName}
        src={userAvatarUrl}
        aria-controls="user-avatar-dropdown-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          border: 'none',
          '&:click': {
            border: 'none',
          },
        }}
      />
      <Menu
        id="user-avatar-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            width: { xs: '180px', md: '240px' },
          },
          autoFocusItem: false,
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems}
      </Menu>
    </ThemeProvider>
  )
}
