import { Box, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { CloseIcon } from '../Icons'
import { EnumeratedComponent } from '../EnumeretadComponent'
import { EnumeratedComponentTypeOnList } from '../EnumeratedComponentList/EnumeratedComponentList'
import { defaultTheme } from '../../theme'

const FirstRow = styled(Box)`
  display: flex;
  justify-content: space-between;
  background: ${() => defaultTheme.backgroundAlt};
  border-radius: 20px 20px 0 0;
  padding: 30px 24px 20px 24px;
`

const SecondRow = styled(Box)`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 25px 40px;
  overflow: scroll;
`

const SubmitRow = styled(Box)`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${() => defaultTheme.dividerColor};
  padding: 16px 40px;
`

type FinalizeMobileViewType = {
  title: string
  totalPrice: string
  mobileSlot: JSX.Element
  mobileSubmitSlot: JSX.Element
  enumeratedComponentList: EnumeratedComponentTypeOnList[]
  handleClose: () => void
}

export default function FinalizeMobileView({
  title,
  totalPrice,
  mobileSlot,
  mobileSubmitSlot,
  enumeratedComponentList,
  handleClose,
}: FinalizeMobileViewType): JSX.Element {
  return (
    <>
      <FirstRow>
        <Grid container>
          <Typography variant="h5" component="h2" style={{ marginRight: 15 }}>
            {title}
          </Typography>
          <Typography variant="h5" component="h2">
            ${totalPrice}
          </Typography>
        </Grid>

        <span style={{ cursor: 'pointer' }} onClick={handleClose}>
          <CloseIcon />
        </span>
      </FirstRow>
      <SecondRow>
        <Box mb={5}>{mobileSlot}</Box>
        <Box>
          {enumeratedComponentList.map((enumeratedComponent, index) => (
            <EnumeratedComponent
              key={enumeratedComponent.title}
              itemCount={`${index + 1}`}
              title={enumeratedComponent.title}
              subtitle={enumeratedComponent.subtitle}
              component={enumeratedComponent.component}
            />
          ))}
        </Box>
      </SecondRow>
      <SubmitRow>{mobileSubmitSlot}</SubmitRow>
    </>
  )
}
