import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m2.84615,11.46154l5.38462,0l0,-6.46154l-5.38462,0l0,6.46154zm0,7.53846l5.38462,0l0,-6.46154l-5.38462,0l0,6.46154zm6.46154,0l5.38466,0l0,-6.46154l-5.38466,0l0,6.46154zm6.46156,0l5.3846,0l0,-6.46154l-5.3846,0l0,6.46154zm-6.46156,-7.53846l5.38466,0l0,-6.46154l-5.38466,0l0,6.46154zm6.46156,-6.46154l0,6.46154l5.3846,0l0,-6.46154l-5.3846,0z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </>
  )
}

export const GridIcon = createIcon(IconContent)
