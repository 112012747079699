import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <>
      <path d="M5.07031 4.92969L19.2124 19.0718" stroke={color} />
      <path d="M5.07031 19.0703L19.2124 4.92818" stroke={color} />
    </>
  )
}

export const CloseIcon = createIcon(IconContent)
