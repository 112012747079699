import {
  Card,
  CardProps,
  CardMedia,
  Typography,
  CardContent,
} from '@mui/material'
import { media } from '../../shared/css-snippets'
import styled from '@emotion/styled'

export const BottomInfo = styled.div`
  position: relative;
  margin-top: 1.25rem;
`
export const TitleSmall = styled(Typography)`
  max-lines: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 3em;
  margin-bottom: 0.5em;
  display: block;
  overflow: hidden;
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.mobile.medium};
  padding-right: 2rem;
  ${() => media.smallDesktopUp} {
    font-size: ${(p) => p.theme.platformStyle.typography.sizes.desktop.medium};
  }
`
export const TitleMedium = styled(TitleSmall)`
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.desktop.medium};
  ${() => media.smallDesktopUp} {
    font-size: 1.25rem;
  }
`

export const TextLineSmall = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.25em;
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.mobile.small};
  ${() => media.smallDesktopUp} {
    font-size: 0.875rem;
  }
`
export const TextLineMedium = styled(Typography)`
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.desktop.small};
  ${() => media.smallDesktopUp} {
    font-size: ${(p) => p.theme.platformStyle.typography.sizes.desktop.medium};
  }
`

export const ConstrainedCardMediaSmall = styled(CardMedia)`
  height: 86px;
  position: relative;
  ${() => media.smallDesktopUp} {
    height: 152px;
  }
`
export const ConstrainedCardMediaMedium = styled(ConstrainedCardMediaSmall)`
  height: 152px;
  ${() => media.smallDesktopUp} {
    height: 200px;
  }
`

type CustomCardProps = {
  disabled: boolean
  size?: 'small' | 'medium'
} & CardProps
export const CustomCard = styled(Card)<CustomCardProps>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: solid 1px ${(p) => p.theme.platformStyle.background};
  ${(p) =>
    !p.disabled &&
    !!p.onClick &&
    `
    &:hover,&:focus {
      border: solid 1px ${p.theme.platformStyle.primaryColor};
    }
  `}
  ${(p) =>
    p.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`

export const CustomCardContent = styled(CardContent)`
  background-color: ${(p) => p.theme.platformStyle.backgroundAlt};
  border-radius: 4px;
`

export const CardTag = styled.div`
  position: absolute;
  top: 14px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0 0.5rem;
  height: 19px;
  border-radius: 8px;
  background-color: #eeeefd;
  color: ${(p) => p.theme.platformStyle.secondaryColor};
`

export const CardTagInnerText = styled(Typography)`
  letter-spacing: 2px;
  font-size: ${(p) => p.theme.platformStyle.typography.sizes.mobile.small};
  text-transform: uppercase;
`
