import { createIcon, IconOptions } from './createIcon'

function IconContent({ color = '#73738C' }: IconOptions) {
  return (
    <>
      <path
        fill={color}
        d="m2.55847,5l1.71667,0l0,14l-1.71667,0l0,-14zm3.43307,0l1.71667,0l0,14l-1.71667,0l0,-14zm14.59168,0l-10.30001,0c-0.47208,0 -0.85833,0.45 -0.85833,1l0,12c0,0.55 0.38625,1 0.85833,1l10.30001,0c0.4721,0 0.8583,-0.45 0.8583,-1l0,-12c0,-0.55 -0.3862,-1 -0.8583,-1zm-9.44168,12.0003l2.14588,-3.15003l1.5364,2.15003l2.1458,-3.22003l2.7553,4.22003l-8.58338,0z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </>
  )
}

export const BurstModeIcon = createIcon(IconContent)
