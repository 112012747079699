import { useState } from 'react'
import { Menu, MenuItem, ThemeProvider, Typography } from '@mui/material'
import { theme } from '../../avenue-8-platform-style'

type MenupickerType = {
  options: string[]
  selectedValue?: string
  maxHeight?: number
  hideBackDrop?: boolean
  onSelect?: (option: string) => void
}

export default function Menupicker({
  options = [],
  selectedValue,
  maxHeight = 230,
  hideBackDrop = true,
  onSelect,
}: MenupickerType): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOptionClick = (option: string) => {
    !!onSelect && onSelect(option)
    handleClose()
  }

  return (
    <ThemeProvider theme={theme}>
      <Typography
        id="basic-Typography"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        fontWeight={400}
        lineHeight={1.25}
        letterSpacing={2}
        fontSize="12px"
        onClick={handleClick}
      >
        FOR{' '}
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          {selectedValue}
        </span>
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        hideBackdrop={hideBackDrop}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            maxHeight,            
          },
        }}
      >
        {options.map((option) => (
          <MenuItem onClick={() => handleOptionClick(option)} key={option}>
            <Typography
              fontWeight={400}
              noWrap
              sx={{ fontSize: { xs: '12px', md: '14px' } }}
            >
              {option}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </ThemeProvider>
  )
}
