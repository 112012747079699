import { CSSProperties, useMemo, useState } from 'react'
import { defaultTheme } from '../../avenue-8-platform-style'
import { GridProps } from '@mui/material/Grid'
import styled from '@emotion/styled'

export type PagePreviewsProps = GridProps & {
  imgMaxWidth?: CSSProperties['maxWidth']
  pages: { url: string; id: string }[]
}

const PreviewContainer = styled.div`
  background: ${defaultTheme?.backgroundAlt ?? 'transparent'};
  flex: 1 1 0px;
  max-width: 50%;
  max-height: 670px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 34px;
  padding-left: 16px;
  padding-right: 16px;
`
const ImagePreview = styled.img`
  object-fit: contain;
  max-height: 80%;
  border-style: 'solid';
  border-width: '1px';
  border-radius: '4px';
  border-color: ${defaultTheme?.dividerColor ?? 'transparent'};
`
const PagesPreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: calc(100% - 32px);
  overflow-x: auto;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
  margin: 30px 0px;
`

const Page = styled.img`
  object-fit: contain;
  height: 60px;
  border-color: ${defaultTheme?.dividerColor ?? 'transparent'};
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  cursor: pointer;
`

export default function PagePreviews(props: PagePreviewsProps): JSX.Element {
  const { pages } = props
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0)
  const selectedPagePreview = useMemo(
    () => pages?.[selectedPageIndex] ?? null,
    [pages, selectedPageIndex],
  )
  return (
    <PreviewContainer>
      {pages.length && selectedPagePreview && (
        <ImagePreview
          src={selectedPagePreview.url}
          alt={`Page ${selectedPageIndex + 1} preview`}
        />
      )}

      {pages.length > 1 && (
        <PagesPreviewContainer>
          {pages.map((preview, idx) => {
            const handleOnClick = () => {
              setSelectedPageIndex(idx)
            }
            return (
              <Page
                src={preview.url}
                alt={`Page ${idx} thumbnail`}
                key={`page-${preview.id}-preview-thumbnail`}
                onClick={handleOnClick}
              />
            )
          })}
        </PagesPreviewContainer>
      )}
    </PreviewContainer>
  )
}
