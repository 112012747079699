import SwiperCore, { Navigation, SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
SwiperCore.use([Navigation])

type SwiperComponentType = {
  children: JSX.Element
  swiperOptions: SwiperOptions
}

export default function SwiperComponent({
  children,
  swiperOptions,
}: SwiperComponentType): JSX.Element {
  return <Swiper {...swiperOptions}>{children}</Swiper>
}

export const SwiperSlideComponent = SwiperSlide
export { SwiperCore }
