import { createIcon, IconOptions } from './createIcon'

function IconContent({ color }: IconOptions) {
  return (
    <>
      <path d="m10 17 4.95-4.95L10 7.1" stroke={color} strokeWidth="1.5" />
    </>
  )
}

export const ChevronIcon = createIcon(IconContent)
